import {pipe, prop, map, path} from 'ramda';
import services from 'services';
import {describeThrow, describeError} from 'utils/errors';
import {
	calendarResourceInclude,
	teamsQueryBase,
	calResQueryBase,
	calendarResourceEncounterInclude,
} from './constants';
import msgs from 'dicts/messages';
import * as normalize from 'utils/normalize';
import {apiUrl} from 'constants/app';
import importMaps from 'services/importMaps';
import {
	maxZoom,
	buildingsMinZoom,
	styles as mapStyles,
	buildingsYearMinZoom,
} from 'constants/maps';
import {
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderBuildingTooltip,
	createMarkerLayer,
	_getMapTileSource,
	_addMarker,
} from 'io/maps';
import {deleteChildren} from 'utils/dom';
import {encodeQuery} from 'utils/url';
import {getJsonProperties, concreteBuildingStyle} from 'utils/maps';
import {mapTooltip} from 'styles/fragments';
import {getResponseData, mapResponseData} from 'utils/app';
import {createEncounterFormFill, getUserTeams} from 'io/data';
import {describeBuildingGetError} from 'utils/data';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getTeams = query =>
	httpJson('get', '/teams', {
		...teamsQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to fetch teams'})))
		.then(pipe(prop('data'), map(normalize.team)));

export const getCalendarResources = query =>
	httpJson('get', `/calendarResource`, {
		include: calendarResourceInclude,
		...calResQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load calendar entries'})))
		.then(pipe(prop('data'), map(normalize.calendarResource)));

export const getCalendarResource = id =>
	httpJson('get', `/calendarResource/${id}`, {
		include: calendarResourceInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load calendar entry'})))
		.then(pipe(prop('data'), normalize.calendarResource));

export const postCalendarResource = resource =>
	httpJson(
		'post',
		'/calendarResource',
		{include: calendarResourceInclude},
		{body: resource},
	)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.calendarResource));

export const deleteCalendarResource = id =>
	httpJson('delete', `/calendarResource/${id}`).catch(e => {
		const reserved = !!e.response && e.response.status === 409;
		const errMsgKey = reserved
			? 'You cannot delete the appointment time because it is already booked. Try refreshing the page'
			: 'Failed to delete entry';
		throw describeError(intl.formatMessage({id: errMsgKey}), e);
	});

export const putCalendarResource = resource =>
	httpJson(
		'put',
		`/calendarResource/${resource.id}`,
		{include: calendarResourceInclude},
		{body: resource},
	)
		.catch(e => {
			const reserved = !!e.response && e.response.status === 409;
			const errMsgKey = reserved
				? 'Entry reserved for another user'
				: 'Failed to save calendar entry';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(pipe(prop('data'), normalize.calendarResource));

export const getBuildings = query =>
	httpJson('get', '/buildings/search', query)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to search buildings'})))
		.then(prop('data'));

export const getUsers = query =>
	httpJson('get', '/users/search', query)
		.catch(describeThrow(intl.formatMessage({id: 'Search failed'})))
		.then(prop('data'));

export const getBuilding = id =>
	httpJson('get', `/buildings/${id}`, {include: 'clients'})
		.catch(e => {
			throw describeBuildingGetError(intl, e);
		})
		.then(prop('data'));

export const getCalendarResourceEncounter = id =>
	httpJson('get', `/calendarResource/${id}`, {
		include: calendarResourceEncounterInclude,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load contact'})))
		.then(
			pipe(
				path(['data', 'reservation', 'data', 'encounter', 'data']),
				normalize.encounter,
			),
		);

export const postBuilding = building =>
	httpJson('post', '/buildings', {}, {body: building})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 409;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(
						describeError(intl.formatMessage({id: 'Failed to create building'}), e),
				  );
		})
		.then(getResponseData(normalize.building));

export const postFormFill = createEncounterFormFill;
export {getUserTeams};

export const getLead = id =>
	httpJson('get', `/leads/${id}`, {include: 'products'})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.lead));

export const getProducts = () =>
	httpJson('get', '/products', {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));

export const postClient = (client, buildingId) =>
	httpJson('post', '/clients', {}, {body: {buildingId, ...client}})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 409;
			e.causedByMarketingPrevented = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(getResponseData(normalize.client));

export const checkTeamCalendarVisibility = teamId =>
	httpJson('get', '/checkTeamCalendarVisibility', {
		teamId,
		include: calendarResourceInclude,
	})
		.then(mapResponseData(map(normalize.calendarResource)))
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

// maps

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

const _getBuildingsStyle =
	({zoom}) =>
	imports => {
		const {openLayers: ol} = imports;

		const sty = mapStyles(ol);
		const keys = ['encounterState', 'manufacturingYear', 'encounterDate', 'banned'];

		return feature => {
			const {encounterState, manufacturingYear, encounterDate, banned} =
				getJsonProperties(feature, keys);

			const building = {encounterState, encounterDate, banned, manufacturingYear};

			return new ol.style.Style({
				...concreteBuildingStyle(ol, {
					geomType: feature.getGeometry().getType(),
					zoom,
					style: sty.buildingStyleProps({
						building,
					}),
				}),
				text:
					zoom >= buildingsYearMinZoom
						? new ol.style.Text({
								text: manufacturingYear ? `${manufacturingYear}` : '',
								overflow: true,
						  })
						: null,
			});
		};
	};
export const getBuildingsStyle = decorateMapMethod(_getBuildingsStyle);

const _getBuildingsSource =
	({apiToken, organizationId}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			// prettier-ignore
			url: `${apiUrl}/maps/buildings/mvt/{z}/{x}/{y}${encodeQuery({organizationId, token: apiToken})}`,
			maxZoom: maxZoom,
		});
	};
export const getBuildingsSource = decorateMapMethod(_getBuildingsSource);

export const getMarketingLeadSources = _ =>
	httpJson('get', `/marketingSources`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

const createBuildingsLayer =
	({apiToken, organizationId, initialZoom}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getBuildingsSource({apiToken, organizationId})(imports),
			style: _getBuildingsStyle({
				zoom: initialZoom,
			})(imports),
			visible: initialZoom >= buildingsMinZoom,
		});

		return layer;
	};

const _initAddBuildingMap =
	({apiToken, organizationId, initialZoom, initialCenter, onDragEnd}) =>
	imports => {
		// keep some local state
		let currHoverBuildingId = null;

		const {openLayers: ol} = imports;

		const mapEl = document.querySelector('#add-building-map');
		if (!mapEl) {
			return Promise.reject(new Error('Map element not found'));
		}

		const view = new ol.View({
			projection: 'EPSG:3857',
			center: initialCenter,
			zoom: initialZoom,
			maxZoom,
			enableRotation: false,
			constrainResolution: true,
		});

		const buildingsLayer = createBuildingsLayer({
			apiToken,
			organizationId,
			initialZoom,
		})(imports);

		const mapSource = _getMapTileSource({sourceId: 'here-streets'})(imports);
		const mapLayer = new ol.layer.Tile({source: mapSource});

		const markerLayer = createMarkerLayer(imports);

		const map = new ol.Map({
			target: 'add-building-map',
			view,
			layers: [mapLayer, buildingsLayer, markerLayer],
		});

		map.on('moveend', e => {
			const zoom = map.getView().getZoom();

			// toggle buildings layer
			if (zoom >= buildingsMinZoom) {
				buildingsLayer.setVisible(true);
			} else {
				buildingsLayer.setVisible(false);
			}
		});

		map.on('pointermove', e => {
			if (e.dragging) return;
			const pixel = map.getEventPixel(e.originalEvent);
			const hit = safeHasFeatureAtPixel(map, pixel, {
				layerFilter: l => l === buildingsLayer || l === markerLayer,
			});
			map.getViewport().style.cursor = hit ? 'pointer' : '';
		});

		const buildingTooltipEl = document.createElement('div');
		buildingTooltipEl.id = 'add-building-map-tooltip';
		buildingTooltipEl.style.cssText = mapTooltip;

		const buildingTooltip = new ol.Overlay({
			element: buildingTooltipEl,
			positioning: 'bottom-center',
			offset: [0, -5],
		});

		const updateBuildingTooltip = buildingFeature => {
			const {
				id,
				address,
				manufacturingYear,
				encounterState,
				encounterDate,
				clientId,
				clientFirstName,
				clientLastName,
			} = getJsonProperties(buildingFeature, [
				'id',
				'address',
				'manufacturingYear',
				'encounterState',
				'encounterDate',
				'clientId',
				'clientFirstName',
				'clientLastName',
			]);

			if (id === currHoverBuildingId) return;

			currHoverBuildingId = id;
			deleteChildren(buildingTooltipEl);
			renderBuildingTooltip({
				tooltipEl: buildingTooltipEl,
				address,
				manufacturingYear,
				encounterState,
				encounterDate,
				clientId,
				clientFirstName,
				clientLastName,
			});
		};

		addTooltipToLayers({
			mapEl,
			map,
			layers: [buildingsLayer],
			tooltipEl: buildingTooltipEl,
			tooltip: buildingTooltip,
			updateTooltip: updateBuildingTooltip,
		});

		_addMarker({
			id: 'new-building-marker',
			coord: map.getView().getCenter(),
			source: markerLayer.getSource(),
			map,
			draggable: true,
			onDragEnd,
		})(imports);

		return Promise.resolve({
			map,
			buildingsLayer,
			mapLayer,
			markerLayer,
		});
	};
export const initAddBuildingMap = decorateMapMethod(_initAddBuildingMap);
