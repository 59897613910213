import {css} from 'styled-components';
import {
	fontSizeXS,
	fontSizeS,
	fontSizeBase,
	fontSizeM,
	fontSizeL,
	fontSizeXL,
	fontSizeXXL,
	fontSizeXXXL,
	textColorBase,
	textColorLight,
	linkColor,
	buttonFillColors,
	buttonFillHoverColors,
	buttonFillFocusColors,
	buttonTextColors,
	buttonTextHoverColors,
	buttonTextFocusColors,
	backgroundColor,
	fontWeightMedium,
	fontWeightRegular,
	radiusDefault,
	radiusCircle,
	radiusNone,
	borderColorInput,
	borderColorBase,
	disabledInputColor,
	highlightColor,
	errorColor,
	errorColorLight,
	errorColorDark,
	colors,
	helpButtonDefaultSize,
	helpButtonBigSize,
	helpButtonSmallSize,
} from './constants';

// typography

export const textStyles = {
	// huge headings
	display1: `
		font-family: rift;
		font-size: ${fontSizeXXXL};
		font-weight: ${fontWeightMedium};
		line-height: 28px;
		letter-spacing: 0.4px;
	`,
	// Dashboard buttons, Large headings
	display2: `
		font-family: rift;
		font-size: ${fontSizeXXL};
		font-weight: ${fontWeightMedium};
		line-height: 24px;
		letter-spacing: 0.4px;
	`,
	display3: `
		font-family: rift;
		font-size: ${fontSizeXL};
		font-weight: ${fontWeightMedium};
		line-height: 24px;
		letter-spacing: 0.3px;
	`,
	// News headings
	display4: `
		font-family: rift;
		font-size: ${fontSizeL};
		font-weight: ${fontWeightMedium};
		line-height: 24px;
		letter-spacing: 0.2px;
	`,
	// Small headings, same style as title
	display5: `
		font-family: rift;
		font-size: ${fontSizeL};
		font-weight: ${fontWeightMedium};
		line-height: 16px;
		letter-spacing: 0.2px;
	`,
	// View title, Panel title
	title: `
		font-family: rift;
		font-size: ${fontSizeL};
		font-weight: ${fontWeightMedium};
		line-height: 16px;
		letter-spacing: 0.2px;
	`,
	// Body text, navigation, table, button
	base: `
		font-family: acumin-pro;
		font-size:  ${fontSizeBase};
		font-weight: ${fontWeightRegular};
		line-height: 16px;
	`,
	// body text, navigation active
	baseMedium: `
		font-family: acumin-pro;
		font-size: ${fontSizeBase};
		font-weight: ${fontWeightMedium};
		line-height: 16px;
	`,
	// input label
	label: `
		font-family: acumin-pro;
		font-size: ${fontSizeS};
		font-weight: ${fontWeightRegular};
		line-height: 16px;
	`,
	// small caption
	caption: `
		font-family: acumin-pro;
		font-size: ${fontSizeXS};
		font-weight: ${fontWeightRegular};
		line-height: 12px;
	`,
};

// remove browser-specific stylings

export const buttonUnstyled = `
	border: none;
	padding: 0;
	text-align: inherit;
	background: none;
	outline: none;
	color: inherit;
`;

export const listUnstyled = `
	margin: 0;
	padding: 0;
	list-style: none;
`;

export const linkUnstyled = `
	color: inherit;
	text-decoration: none;
`;

export const textUnstyled = `
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
`;

export const pUnstyled = `
	margin: 0;
`;

export const headingUnstyled = `
	${textUnstyled};

	margin: 0;
	padding: 0;
`;

export const fieldSetUnstyled = `
	margin: 0;
	border: none;
	padding: 0;
	min-width: auto;
`;

export const legendUnstyled = `
	display: inline;
`;

export const labelUnstyled = ``;

// use nasty > selectors to ensure no problems arise if tables ever get nested
export const tableUnstyled = `
	border-spacing: 0;

	> * > tr {
		> td, > th {
			padding: 0;
		}

		> th {
			font-weight: inherit;
		}
	}
`;

export const hrUnstyled = `
	margin: 0;
	border: none;
`;

// reset most inherited styles. https://www.w3.org/TR/CSS21/propidx.html

export const noInherit = `
	border-collapse: initial;
	border-spacing: initial;
	caption-side: initial;
	color: initial;
	cursor: initial;
	direction: initial;
	elevation: initial;
	empty-cells: initial;
	font-family: initial;
	font-size: initial;
	font-style: initial;
	font-variant: initial;
	font-weight: initial;
	font: initial;
	letter-spacing: initial;
	line-height: initial;
	list-style-image: initial;
	list-style-position: initial;
	list-style-type: initial;
	list-style: initial;
	quotes: initial;
	text-align: initial;
	text-indent: initial;
	text-transform: initial;
	visibility: initial;
	white-space: initial;
	word-spacing: initial;
`;

// base styles

export const rootStyle = `
	overflow: auto;
	height: 100%;
	font-size: ${fontSizeBase};
	font-family: acumin-pro, sans-serif;
	background: ${backgroundColor};
	color: ${textColorBase};
	-webkit-font-smoothing: antialiased;

	* {
		-webkit-overflow-scrolling: touch; // added for iOS mobile devices
	}

	b, strong {
		font-weight: ${fontWeightMedium}; // add correct bold font-weight
	}
`;

// mixins

export const disableableMargins = css`
	${({noTopMargin}) => (noTopMargin ? 'margin-top: 0;' : '')};
	${({noBottomMargin}) => (noBottomMargin ? 'margin-bottom: 0;' : '')};
`;

// use to center icon and text vertically inside button etc
export const centerVerticallyInline = css`
	${({centerVerticallyInline}) =>
		centerVerticallyInline ? '> span { vertical-align: middle; }' : ''};
`;

// public base styles for components

export const link = css`
	${linkUnstyled};

	color: ${linkColor};

	&:hover {
		text-decoration: underline;
	}
`;

export const h1 = css`
	${headingUnstyled};
	${textStyles.display1};

	margin-top: 25px;
	margin-bottom: 20px;

	${disableableMargins};
`;

export const h2 = css`
	${headingUnstyled};
	${textStyles.display2};

	margin-top: 25px;
	margin-bottom: 20px;

	${disableableMargins};
`;

export const h3 = css`
	${headingUnstyled};
	${textStyles.display3};

	margin-top: 25px;
	margin-bottom: 20px;

	${disableableMargins};
`;

export const h4 = css`
	${headingUnstyled};
	${textStyles.display4};

	margin-top: 25px;
	margin-bottom: 20px;

	${disableableMargins};
`;

export const h5 = css`
	${headingUnstyled};
	${textStyles.display5};

	margin-top: 25px;
	margin-bottom: 20px;
	${disableableMargins};
`;

export const p = css`
	${pUnstyled};

	margin-top: 20px;
	margin-bottom: 20px;
	${disableableMargins};
`;

const list = css`
	padding-left: 20px;

	margin-top: 20px;
	margin-bottom: 20px;
	${disableableMargins};

	> * {
		margin-top: 10px;
		margin-bottom: 10px;

		/* inner lists shouldn't have margins. the possible inner list should be a direct descendant of a list item element, otherwise it's considered unrelated content and gets to have its own margins. */
		> ul,
		> ol,
		> dl {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
`;

export const ul = list;

export const ol = list;

export const textInput = css`
	border-width: 1px;
	border-style: solid;
	border-color: ${({isValid}) => (isValid ? borderColorInput : errorColor)};
	color: ${({isValid}) => (isValid ? textColorBase : errorColorDark)};
	background: ${({isValid}) => (isValid ? 'white' : errorColorLight)};
	padding: 0 15px;
	height: 35px;
	// text may not get vertically centered on mobile without this
	line-height: 35px;
	border-radius: 3px;

	&:focus {
		border-color: ${({isValid}) => (isValid ? highlightColor : errorColor)};
	}
`;

export const inputTypes = {
	date: textInput,
	'datetime-local': textInput,
	email: textInput,
	month: textInput,
	number: textInput,
	password: textInput,
	tel: textInput,
	text: textInput,
	time: textInput,
	url: textInput,
	week: textInput,
};

export const input = css`
	font-size: ${fontSizeBase};
	color: ${textColorBase};
	${({block}) => (block ? 'display: block;' : '')};
	${({stretch}) => (stretch ? 'width: 100%;' : '')};
	// inputs have a static width by default so they won't fit to parent container unless max-width is specified
	${({fit}) => (fit ? 'max-width: 100%;' : '')};
	${({type = 'text'}) => inputTypes[type] || ''};

	&:disabled {
		background: ${disabledInputColor};
	}

	outline: none;
`;

// mostly a mish-mash of input & textInput
export const textarea = css`
	resize: auto;

	font-size: ${fontSizeBase};
	color: ${({isValid}) => (isValid ? textColorBase : errorColorDark)};
	background: ${({isValid}) => (isValid ? 'white' : errorColorLight)};
	${({block}) => (block ? 'display: block;' : '')};
	${({stretch}) => (stretch ? 'width: 100%;' : '')};

	border-width: 1px;
	border-style: solid;
	border-color: ${({isValid}) => (isValid ? borderColorInput : errorColor)};
	// 10 px vertical padding goes nicely with our line height (1.15 default from normalize.css) to make 1-row textareas appear the same height as inputs
	padding: 10px 15px;
	border-radius: 3px;
	outline: none;

	&:disabled {
		background: ${disabledInputColor};
	}

	&:focus {
		border-color: ${({isValid}) => (isValid ? highlightColor : errorColor)};
	}
`;

export const select = css`
	${({block}) => (block ? 'display: block;' : '')};
	border-width: 1px;
	border-style: solid;
	border-color: ${({isValid}) => (isValid ? borderColorInput : errorColor)};
	padding: 0 15px;
	height: 35px;
	// text may not get vertically centered on mobile without this
	line-height: 35px;
	${({stretch}) => (stretch ? 'width: 100%;' : '')};
	font-size: ${fontSizeBase};
	border-radius: 3px;
	appearance: none;
	background: ${({isValid}) => (isValid ? 'white' : errorColorLight)};
	color: ${({isValid}) => (isValid ? textColorBase : errorColorDark)};
	outline: none;

	&:disabled {
		background: ${disabledInputColor};
	}

	&:focus {
		border-color: ${({isValid}) => (isValid ? highlightColor : errorColor)};
	}
`;

export const formBlock = css`
	position: relative;
	margin-top: 20px;
	margin-bottom: 20px;
	${({noWrap}) => (noWrap ? 'white-space: nowrap;' : '')};

	${disableableMargins};
`;

export const label = css`
	${labelUnstyled};
	display: ${({inline}) => (inline ? 'inline' : 'inline-block')};
	${({bottomSpacing}) => (bottomSpacing ? 'margin-bottom: 10px;' : '')};
	${({noWrap}) => (noWrap ? 'white-space: nowrap;' : '')};
	font-size: ${fontSizeS};
	line-height: 1.92;
	color: ${textColorBase};

	${({required}) =>
		required
			? `
		&::after {
			content: ' *';
			color: red;
			position: absolute;
			top: 0;
			margin-left: 0.25rem;
		}
	`
			: ''};
`;

export const fieldSet = css`
	${fieldSetUnstyled};
	${formBlock};
`;

export const legend = css`
	${legendUnstyled};
	${label};
`;

// use nasty > selectors to ensure no problems arise if tables ever get nested
export const table = css`
	${tableUnstyled};

	width: ${({width = '100%'}) => width};

	> * > tr {
		> th,
		> td {
			padding: ${({slimRows}) => (slimRows ? '8px 8px' : '15px 15px')};
		}

		> th {
			color: ${textColorLight};
		}
	}

	> thead > tr {
		> th {
			border-bottom: 1px solid ${borderColorBase};
			padding-top: 15px;
			padding-bottom: 8px;
		}
	}

	> tbody > tr:not(:first-child) {
		> th,
		> td {
			border-top: 1px solid ${borderColorBase};
		}
	}

	${
		/*prettier-ignore*/ ({borderBottom}) => (borderBottom ? `
		> tbody > tr:last-child {
			> th, > td {
				border-bottom: 1px solid ${borderColorBase};
			}
		}
	` : '')
	};

	${
		/*prettier-ignore*/ ({verticalAlign}) => (verticalAlign ? `
		> * > tr {
			> th, > td {
				vertical-align: ${verticalAlign};
			}
		}
	` : '')
	};

	${
		/*prettier-ignore*/ ({hover}) => (hover ? `
		> tbody > tr:hover {
			> td, > th {
				background: ${backgroundColor};
				cursor: default;
			}
		}
	` : '')
	};

	${
		/*prettier-ignore*/ ({largeSidePadding}) => (largeSidePadding ? `
		> * > tr {
			> th, > td {
				&:first-child {
					padding-left: 20px;
				}
				&:last-child {
					padding-right: 20px;
				}
			}
		}
	` : '')
	};
`;

export const filledButton = css`
	${({appearance = 'default'}) => `
		background: ${buttonFillColors[appearance]};
		color: ${buttonTextColors[appearance]};

		&:disabled {
			opacity: 0.6;
		}

		&:not(:disabled) {
			&:hover {
				background: ${buttonFillHoverColors[appearance]};
				color: ${buttonTextHoverColors[appearance]};
				${appearance === 'link' ? 'cursor: pointer;' : ''}
			}
			&:focus {
				background: ${buttonFillFocusColors[appearance]};
				color: ${buttonTextFocusColors[appearance]};
			}
		}
	`};
`;

export const buttonXS = css`
	padding: 3px 8px;
	font-size: ${fontSizeS};
`;
export const buttonS = css`
	padding: 5px 10px;
`;
export const buttonM = css`
	padding: 7px 12px;
`;
export const buttonL = css`
	padding: 9px 14px;
`;
export const buttonXL = css`
	padding: 10px 17px;
	font-size: ${fontSizeM};
`;

export const buttonSizes = {
	xs: buttonXS,
	s: buttonS,
	m: buttonM,
	l: buttonL,
	xl: buttonXL,
};

export const button = css`
	${buttonUnstyled};

	outline: none;
	${filledButton};
	${({size = 'm'}) => buttonSizes[size]};
	${({stretch}) => (stretch ? 'width: 100%' : '')};

	border-radius: ${
		/*prettier-ignore*/ ({circle, square}) =>
		circle ? radiusCircle
		: square ? radiusNone
		: radiusDefault
	};

	${({center}) => (center ? 'text-align: center;' : '')};
	${centerVerticallyInline};
`;

export const textButton = css`
	${buttonUnstyled};

	color: ${textColorLight};

	&:hover,
	&:focus {
		color: ${textColorBase};
	}
`;

// less general mixins

export const spacedItems = css`
	margin-top: -10px;
	margin-left: -12px;

	> * {
		margin-top: 10px;
		margin-left: 12px;
	}
`;

export const spacedInputs = css`
	margin-top: -15px;
	margin-left: -20px;

	> * {
		margin-top: 15px;
		margin-left: 20px;
	}
`;

export const popup = css`
	padding: ${({noPadding}) => (noPadding ? '0' : '20px')} 10px;
	background: white;
	border: 1px solid ${borderColorBase};
	border-radius: 5px;
	box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
	/* reset possible parent influence */
	${noInherit};
	${textStyles.base};
	color: ${textColorBase};
`;

export const popupDark = css`
	padding: 10px;
	background: ${colors.grey2};
	border-radius: ${radiusDefault};
	color: white;
`;

// TODO: does this number work nicely with other components
const overlayWrapperZ = 10;

export const overlayWrapper = `
	position: absolute;
	z-index: ${overlayWrapperZ};
`;

// overlay wrapper positions
export const overlayLeft = ({distance}) => css`
	right: calc(100% + ${distance ? distance : '0px'});
`;
export const overlayRight = ({distance}) => css`
	left: calc(100% + ${distance ? distance : '0px'});
`;
export const overlayTop = ({distance}) => css`
	bottom: calc(100% + ${distance ? distance : '0px'});
`;
export const overlayBottom = ({distance}) => css`
	top: calc(100% + ${distance ? distance : '0px'});
`;
export const overlayVertCenter = ({offset}) => css`
	top: calc(50% + ${offset ? offset : '0px'});
	transform: translateY(-50%);
`;
export const overlayVertStart = ({offset}) => css`
	top: ${offset ? offset : '0px'};
`;
export const overlayVertEnd = ({offset}) => css`
	bottom: ${offset ? offset : '0px'};
`;
export const overlayHorizCenter = ({offset}) => css`
	left: calc(50% + ${offset ? offset : '0px'});
	transform: translateX(-50%);
`;
export const overlayHorizStart = ({offset}) => css`
	left: ${offset ? offset : '0px'};
`;
export const overlayHorizEnd = ({offset}) => css`
	right: ${offset ? offset : '0px'};
`;

export const overlayShowAnimated = ({animation}) => css`
	opacity: 0;
	z-index: -1;
	${
		/*prettier-ignore*/ ({show}) => show ? `
		opacity: 1;
		z-index: ${overlayWrapperZ};
	` : ''
	};
	transition: opacity ${animation};
`;

// hard-coded default animation, note that it doesn't work on fade
export const popperOverlayAnimated = css`
	@keyframes opacity {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}

	z-index: ${overlayWrapperZ};
	${
		/*prettier-ignore*/ ({show, outOfBoundaries}) => (!show || outOfBoundaries ? `
		/* actually hiding the Popper instead of making it invisible seems to spare a bit of resources. */
		display: none;
	` : '')
	};
	animation: opacity 100ms ease-out;
`;

export const mapTooltip = `
	padding: 10px;
	border: 1px solid ${borderColorBase};
	border-radius: 3px;
	text-align: center;
	background: white;
	opacity: 0.85;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.05);
`;

// absolute position doesn't work inside flex containers, use hZero or vZero there to make it take zero space. don't use margins with absolute or zero-size versions, instead favor transX or transY. use rootCss to provide any custom css.
export const helpButton = css`
	${/*prettier-ignore*/ ({absolute}) => absolute ? `
		position: absolute;
	` : ''}

	${
		/*prettier-ignore*/ ({marginTop, marginBottom, marginLeft, marginRight, vZero, hZero, primary, small}) => `
		margin-top: ${marginTop};
		margin-bottom: ${
			vZero && primary ? `-${helpButtonBigSize}`
			: vZero && small ? `-${helpButtonSmallSize}`
			: vZero ? `-${helpButtonDefaultSize}`
			: marginBottom
		};
		margin-left: ${marginLeft};
		margin-right: ${
			hZero && primary ? `-${helpButtonBigSize}`
			: hZero && small ? `-${helpButtonSmallSize}`
			: hZero ? `-${helpButtonDefaultSize}`
			: marginRight
		};
	`
	};

	transform: translate(${({transX = '0', transY = '0'}) => `${transX}, ${transY}`});

	/* sizes matching the icon */
	height: ${({primary, small}) =>
		primary ? helpButtonBigSize : small ? helpButtonSmallSize : helpButtonDefaultSize};
	width: ${({primary, small}) =>
		primary ? helpButtonBigSize : small ? helpButtonSmallSize : helpButtonDefaultSize};
	line-height: 0;
	font-size: ${({primary, small}) =>
		primary ? fontSizeXXL : small ? fontSizeL : fontSizeXL};

	color: ${({primary, dark, darker}) =>
		darker ? '#adadad' : primary || dark ? '#cccccc' : '#dbdbdb'};
	cursor: help;

	/* also set line-height to 0 on icon element */
	> * {
		line-height: 0;
	}

	&& {
		${({rootCss = ''}) => rootCss};
	}
`;
