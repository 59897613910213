import services from 'services';
import {describeThrow, describeError} from 'utils/errors';
import * as normalize from 'utils/normalize';
import {messages} from 'constants/loc';
import {getResponseData, mapResponseData} from 'utils/app';
import msgs from 'dicts/messages';
import {userInclude} from './constants';
import {map, pipe, prop} from 'ramda';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const calResQueryBase = {
	_limit: 999,
	searchByPreviousDates: true,
};

export const calendarEventInclude = 'user,calendarEventType,building,client';

export const getUser = (includes = userInclude) =>
	httpJson('get', '/users/me', includes)
		.catch(describeThrow(messages[msgs.contentFetchFailed]))
		.then(getResponseData(normalize.user));

export const getPersonalNotices = query =>
	httpJson('get', `/notices`, {...query, personalOnly: true, _limit: 10})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.notice)));

export const postPersonalNoticeSeen = (userId, noticeId, metaField) =>
	httpJson(
		'post',
		`/notices/updatePersonalSeen`,
		{},
		{body: {userId, notices: [{id: noticeId}], metaField}},
	).catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})));

// all user's unseen personal notices are set seen when only userId posted
export const postAllPersonalNoticesSeen = userId =>
	httpJson('post', `/notices/updatePersonalSeen`, {}, {body: {userId}}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const postImpersonateUser = userId =>
	httpJson('post', `/users/${userId}/impersonate`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const postStartTimer = type =>
	httpJson('post', '/timeEntries/createTimer', {}, {body: {type}})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 500;
			e.causedByTimeEntryConflict = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(pipe(prop('data'), normalize.timeEntry));

export const postStopTimer = () =>
	httpJson('post', '/timeEntries/stopActiveTimer', {})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to stop recording work time'})))
		.then(prop('data'));

export const postFeedback = form =>
	httpJson('post', '/feedback', {}, {body: form}).catch(
		describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})),
	);

export const getApiKeys = () =>
	httpJson('get', '/apiKeys').catch(
		describeThrow(intl.formatMessage({id: 'Could not load application data'})),
	);
export const enioChangeOrganization = () =>
	httpJson('post', '/enioCaller/setOrganization')
		.catch(describeThrow('Error: Cannot create enio connetion'))
		.then(prop('data'), normalize.enioCaller);

export const enioGetOrganization = () =>
	httpJson('get', '/enioCaller/getOrganization').catch('Error!').then(prop('data'));

export const enioGetCredentials = userId =>
	httpJson('get', '/users/' + userId + '?include=enioCallerSettings')
		.catch(describeThrow(intl.formatMessage({id: 'Could not fetch eniocaller settings'})))
		.then(prop('data'))
		.then(prop('enioCallerSettings'));

export const fetchReasonMappings = () =>
	httpJson('get', '/reasonMappings')
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(prop('data'));

export const getTags = query =>
	httpJson('get', '/tags', query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(map(normalize.tag)));

export const attachTag = ({id, ...rest}) =>
	httpJson('post', `/tags/${id}/attach`, {}, {body: rest})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.tag));

export const detachTag = ({id, ...rest}) =>
	httpJson('post', `/tags/${id}/detach`, {}, {body: rest})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.tag));

export const getBuildingsTags = buildingId =>
	httpJson('get', `/buildings/${buildingId}/tags`).then(getResponseData(normalize.tag));

export const getActiveCallPools = () =>
	httpJson('get', '/callPools/actives', {})
		.catch(e => {
			const errMsgKey = 'Failed to load callable buildings';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(mapResponseData(map(normalize.callPool)));

export const getCallPool = id =>
	httpJson('get', `/callPools/${id}`)
		.catch(e => {
			const errMsgKey = 'Failed to load callable buildings';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(getResponseData(normalize.callPool));

// NOTE: almost duplicate of searchCallLogs in src/modules/overviewApp/trackingPage/io.js
export const searchCallLogs = query =>
	httpJson('get', `/callLogs/tracking`, query)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getCallCampaigns = ({userId, type = 'call'}) =>
	httpJson('get', '/callCampaigns', {
		type,
		userId,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Campaigns failed to load'})))
		.then(mapResponseData(map(normalize.callCampaign)));

export const putCallCampaign = campaign =>
	httpJson('put', `/callCampaigns/${campaign.id}`, {}, {body: campaign})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save'})))
		.then(getResponseData(normalize.callCampaign));

export const postCallCampaign = campaign =>
	httpJson('post', '/callCampaigns', {}, {body: campaign})
		.catch(describeThrow(intl.formatMessage({id: 'Campaign creation failed'})))
		.then(getResponseData(normalize.callCampaign));

export const deleteCallCampaign = id =>
	httpJson('delete', `/callCampaigns/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Campaign deletion failed'})),
	);

export const getProducts = q =>
	httpJson('get', '/products', q)
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.product)));

export const getCalendarEvent = id =>
	httpJson('get', `/calendarEvents/${id}`, {
		include: 'user,calendarEventType,building',
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load calendar entries'})))
		.then(pipe(prop('data'), normalize.calendarEvent));

export const getCalendarEvents = query =>
	httpJson('get', '/calendarEvents', {
		include: calendarEventInclude,
		...calResQueryBase,
		...query,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load calendar entries'})))
		.then(pipe(prop('data'), map(normalize.calendarEvent)));

export const postCalendarEvent = resource =>
	httpJson('post', '/calendarEvents', {include: calendarEventInclude}, {body: resource})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.calendarEvent));

export const getCalendarEventTypes = query =>
	httpJson('get', '/calendarEventTypes', query)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to load calendar event types'})))
		.then(pipe(prop('data'), map(normalize.calendarEventType)));

export const postCalendarEventType = resource =>
	httpJson('post', '/calendarEventTypes', {}, {body: resource})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save calendar event type'})))
		.then(getResponseData(normalize.calendarEventType));

export const putCalendarEventType = resource =>
	httpJson('put', `/calendarEventTypes/${resource.id}`, {}, {body: resource})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save calendar event type'})))
		.then(getResponseData(normalize.calendarEventType));

export const deleteCalendarEventType = id =>
	httpJson('delete', `/calendarEventTypes/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete calendar event type'})),
	);

export const deleteCalendarEvent = id =>
	httpJson('delete', `/calendarEvents/${id}`).catch(
		describeThrow(intl.formatMessage({id: 'Failed to delete calendar event'})),
	);

export const restoreCalendarEvent = id =>
	httpJson('post', `/calendarEvents/${id}/restore`)
		.catch(describeThrow(intl.formatMessage({id: 'Failed to restore calendar event'})))
		.then(pipe(prop('data'), normalize.calendarEvent));

export const putCalendarEvent = resource =>
	httpJson('put', `/calendarEvents/${resource.id}`, {}, {body: resource})
		.catch(e => {
			const errMsgKey = 'Failed to save calendar event';
			throw describeError(intl.formatMessage({id: errMsgKey}), e);
		})
		.then(pipe(prop('data'), normalize.calendarEvent));
