import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components';
import {textarea} from 'styles/fragments';

const StyledTextArea = styled.textarea`
	${textarea};
`;

const TextArea = React.forwardRef((props, ref) => {
	const {meta} = props;
	const isValid = meta ? !((meta.submitFailed || meta.touched) && meta.error) : true;
	return <StyledTextArea ref={ref} isValid={isValid} {...props} />;
});

TextArea.displayName = 'TextArea';

TextArea.propTypes = {
	meta: propTypes.object,
};

export default TextArea;
