import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const recheckQuery = creator('recheckQuery', P.Boolean);

export const changePage = creator('changePage');

export const setBuildingsType = creator('setBuildingsType', P.String);

export const setPerPage = creator('setPerPage');

export const setSort = creator('setSort');

export const setSortDir = creator('setSortDir');

export const setQuery = creator('setQuery');

export const clearQuery = creator('clearQuery');

export const toggleMassEditor = creator('toggleMassEditor');

export const selectBuilding = creator('selectBuilding');

export const clearMassSelection = creator('clearMassSelection');

export const massSelect = creator('massSelect');

export const updateSelectedBuildings = creator('updateSelectedBuildings');

export const openAreasSelector = creator('openAreasSelector');

export const closeAreasSelector = creator('closeAreasSelector');

export const setAreasSelectorType = creator('setAreasSelectorType', P.String);

export const setAreasSelectorSearch = creator('setAreasSelectorSearch', P.String);

export const openAreasSelectorSuggestion = creator(
	'openAreasSelectorSuggestion',
	P.String,
);

export const _initialize = creator('_initialize');

export const _startOp = creator('_startOp');

export const _opOk = creator('_opOk');

export const _opFailed = creator('_opFailed');

export const updateMetaData = creator('updateMetaData');

export const _setQuery = creator('_setQuery');

export const _setBuildings = creator('_setBuildings');

export const _setMassSelection = creator('_setMassSelection');

export const _resetMassEditor = creator('_resetMassEditor');

export const getAvailableTags = creator('getAvailableTags');

export const _getAvailableTags = creator('_getAvailableTags', P.Array);

export const openCsvFileSelector = creator('openCsvFileSelector');

export const closeCsvFileSelector = creator('closeCsvFileSelector');

export const clearCsvFileSelection = creator('clearCsvFileSelection');

export const searchProducts = creator('searchProducts', P.Object);

export const csvFileSelectorUpload = creator('csvFileSelectorUpload', P.Object);

export const _csvFileSelectorUpload = creator('_csvFileSelectorUpload', P.Object);
