import {action} from 'utils/redux';
import {P, PaginatedData, Nullable} from 'utils/types';
import namespace from '../namespace';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const openPlacesSuggestion = creator('openPlacesSuggestion', P.String);

export const updateMapQuery = creator('updateMapQuery', P.Object);

export const setLayerVisibility = creator('setLayerVisibility', P.Object);

export const setActiveCallPool = creator('setActiveCallPool', P.Number);

export const clearActiveCallPool = creator('clearActiveCallPool');

export const boostCallPoolArea = creator('boostCallPoolArea', P.Object);

export const removeCallPoolArea = creator('removeCallPoolArea', P.Object);

export const setSelection = creator('setSelection', P.Object);

export const setAreaMultiSelection = creator('setAreaMultiSelection', P.Object);

export const clearSelection = creator('clearSelection');

export const openMassEditor = creator('openMassEditor', P.Array);

export const addAreasToCallPool = creator('addAreasToCallPool', P.Object);

export const createIssue = creator('createIssue', P.Object);

export const removeIssue = creator('removeIssue', P.Number);

export const saveCallPoolArea = creator('saveCallPoolArea', P.Object);

export const setSelectionInfoTab = creator('setSelectionInfoTab', P.String);

export const openOffersModal = creator('openOffersModal');

export const closeOffersModal = creator('closeOffersModal');

export const changeOffersPage = creator('changeOffersPage', P.Number);

export const openOffer = creator('openOffer', P.Object);

export const updateActiveCallPoolAreas = creator('updateActiveCallPoolAreas', P.Number);

export const startDrawArea = creator('startDrawArea', P.String);

export const clearDraw = creator('clearDraw');

export const toggleCustomAreaCreator = creator('toggleCustomAreaCreator');

export const createCustomArea = creator('createCustomArea');

export const removeLastPoint = creator('removeLastPoint');

export const removeCustomArea = creator('removeCustomArea', P.Object);

export const toggleCallPoolCreator = creator('toggleCallPoolCreator');

export const removeCallPool = creator('removeCallPool', P.Number);

export const saveCallPool = creator('saveCallPool');

export const searchBuildings = creator('searchBuildings');

export const showBuildingOnMap = creator('showBuildingOnMap', P.Object);

export const setMapSource = creator('setMapSource', P.String);

export const updateMapBuildings = creator('updateMapBuildings');

export const openAreaSettingsModal = creator('openAreaSettingsModal');

export const closeAreaSettingsModal = creator('closeAreaSettingsModal');

export const callBuilding = creator('callBuilding', P.Number);

export const editBuilding = creator('editBuilding', P.Number);

export const openEncounterModal = creator('openEncounterModal', P.Number);

export const closeEncounterModal = creator('closeEncounterModal');

export const toggleMobileSidebar = creator('toggleMobileSidebar');

export const openProfinderListCreator = creator('openProfinderListCreator');

// closed due to purchase (not cancel)?
export const closeProfinderListCreator = creator('closeProfinderListCreator', P.Boolean);

export const deleteProfinderList = creator('deleteProfinderList', P.Number);

export const _updateMapQuery = creator('_updateMapQuery', P.Object);

export const _setCallPools = creator('_setCallPools', P.Array);

export const _setActiveCallPool = creator('_setActiveCallPool', P.Object);

export const _setActiveCallPoolLoading = creator('_setActiveCallPoolLoading', P.Boolean);

export const _addAreasToCallPool = creator('_addAreasToCallPool', P.Array);

export const _removeCallPoolArea = creator('_removeCallPoolArea', P.Number);

export const _setSelection = creator('_setSelection', P.Object);

export const _setSelectionLoading = creator('_setSelectionLoading', P.Boolean);

export const _setIssues = creator('_setIssues', P.Array);

export const _createIssue = creator('_createIssue', P.Object);

export const _removeIssue = creator('_removeIssue', P.Number);

export const _setIssueCSV = creator('_setIssueCSV', P.Object);

export const _setCallableBuildings = creator('_setCallableBuildings', P.Object);

export const _setCallableBuildingsLoading = creator(
	'_setCallableBuildingsLoading',
	P.Number,
);

export const _setAllCallableBuildingsLoading = creator('_setAllCallableBuildingsLoading');

export const _setTeams = creator('_setTeams', P.Array);

export const _saveCallPoolArea = creator('_saveCallPoolArea', P.Object);

export const _setOffers = creator('_setOffers', PaginatedData(P.Array));

export const _setCallPoolArea = creator('_setCallPoolArea', P.Object);

export const _updateActiveCallPoolAreas = creator('_updateActiveCallPoolAreas', P.Array);

export const _endDrawArea = creator('_endDrawArea', Nullable(P.Object));

export const _endDrawing = creator('_endDrawing');

export const _clearDraw = creator('_clearDraw');

export const _setShowDrawMenu = creator('_setShowDrawMenu', P.Boolean);

export const _setLayerVisibility = creator('_setLayerVisibility', P.Object);

export const _clearSelection = creator('_clearSelection');

export const _setProducts = creator('_setProducts', P.Array);

export const _removeCallPool = creator('_removeCallPool', P.Number);

export const _updateCallPool = creator('_updateCallPool', P.Object);

export const _addCallPool = creator('_addCallPool', P.Object);

export const _updateActiveCallPoolData = creator('_updateActiveCallPoolData', P.Object);

export const _clearActiveCallPool = creator('_clearActiveCallPool');

export const _setActiveCallPoolQuery = creator('_setActiveCallPoolQuery', P.Number);

export const _setBuildings = creator('_setBuildings');

export const _setEncounters = creator('_setEncounters', P.Array);

export const _setActiveEncounterSource = creator('_setActiveEncounterSource', P.Object);

export const _setOrganizations = creator('_setOrganizations', P.Array);

export const _setSmartDataOptions = creator('_setSmartDataOptions', P.Array);

export const _setProfinderListsProcessing = creator(
	'_setProfinderListsProcessing',
	P.Boolean,
);

export const _setCreatingProfinderList = creator('_setCreatingProfinderList', P.Boolean);

export const _startOp = creator('_startOp');

export const _opFailed = creator('_opFailed');

export const _opOk = creator('_opOk');

export const _initialize = creator('_initialize');

export const _setAvailableTags = creator('_setAvailableTags', P.Array);

export const fetchUsersByTeamId = creator('fetchUsersByTeamId', P.Number);

export const _fetchUsersByTeamId = creator('_fetchUsersByTeamId', P.Object);

export const searchDrawnPolygon = creator('searchDrawnPolygon');
