import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const reInitialize = creator('reInitialize');

export const destroy = creator('destroy');

export const saveLead = creator('saveLead');

export const searchBuildings = creator('searchBuildings', P.Object);

export const searchSalesmanagers = creator('searchSalesmanagers');

export const setBuilding = creator('setBuilding');

export const setProcessing = creator('setProcessing');

export const resetForm = creator('resetForm');

export const openAddBuildingModal = creator('openAddBuildingModal', P.Object);

export const closeAddBuildingModal = creator('closeAddBuildingModal');

export const openMapModal = creator('openMapModal', P.Object);

export const closeMapModal = creator('closeMapModal');

export const addBuilding = creator('addBuilding');

export const addClientToBuilding = creator('addClientToBuilding');

export const restoreLead = creator('restoreLead', P.Number);

export const updateClientData = creator('updateClientData');

export const _clientSaved = creator('_clientSaved');

export const _setBuildingToAddCoords = creator('_setBuildingToAddCoords', P.Array);

export const _addBuilding = creator('_addBuilding');

export const _addClient = creator('_addClient');

export const _initialize = creator('_initialize');

export const _opFailed = creator('_opFailed');

export const _setBuildings = creator('_setBuildings');

export const _setSalesmanagers = creator('_setSalesmanagers');

export const _setProducts = creator('_setProducts');

export const _setCategories = creator('_setCategories');

export const _setCustomerTypes = creator('_setCustomerTypes');

export const _setSources = creator('_setSources');
