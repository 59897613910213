export const type = {
	visit: 'Visit',
	call: 'Phone call',
	salesmanVisit: 'Sales',
	deal: 'Sale',
	issue: 'Issue',
	project: 'Project',
	unknown: 'Not known',
	none: 'Nothing',
	salesAssignment: 'Sales assignment',
	calendarResource: 'Appointment time [visit]',
	massUpdate: 'Mass update',
};

export const state = {
	call: 'Call booked',
	cancelled: 'Lost',
	cancelledDeal: 'Cancelled sale',
	cancelledOffer: 'Offer declined',
	contacted: 'Contacted',
	deal: 'Sale made',
	installed: 'Installed',
	installing: 'Installing',
	issue: 'Issue',
	newAppointment: 'New visit',
	notEncountered: 'Not contacted',
	notReached: 'Not reached',
	offer: 'Offer made',
	takeContact: 'Take contact',
	unknown: 'Not known',
	visit: 'Visit booked [alt]',
	salesAssignment: 'Sales assignment',
	salesAssignmentExpired: 'Sales assignment, expired',
	cancelledCalendarResource: 'Cancelled visit',
	// the ones below are actually d2d encounter states
	notHome: 'Not home',
	removed: 'Removed',
	removedFromAllOrganizations: 'Removed (All organizations)',
};

export const reason = {
	overage: 'Too old [alt]',
	underage: 'Minor',
	marketingPrevented: 'Marketing ban',
	wrongPhone: 'Invalid number',
	removeClient: 'Delete client information',
	banBuilding: 'Add building to ban list',
};

export const cancelledDealStateReason = {
	cancelledByOffspring: "Customer's children cancel",
	thirdPartyConsultation: 'Third party had consulted',
	priceTooHigh: 'The customer found the price too high',
	badTiming: 'Bad timing',
	renovationUnnecessary: 'The customer did not find the service offered necessary',
	noFunding: 'Funding was not accepted',
	wantedLaterContact: 'The customer requested to be contacted later',
	other: 'Other',
};

export const contactedStateReason = {
	notInterested: "I didn't get the customer interested in the service",
	couldNotEnter: 'I could not enter the house',
	noTimeYet: 'I could not make customer decide yet, I will be in touch at',
	notAbleToDecide: 'Customer was not able to decide',
	customerDataToBeDeleted:
		'Customer info to be deleted from organisation (contact Sales Manager)',
	other: 'Other',
};

export const offerStateReason = {
	notInterested: 'I could not raise interest enough',
	noTrust: 'I could not raise trust enough',
	notExcited: 'I could not make customer excited enough',
	noDecisionYet: 'I could not make customer decide yet, I will be in touch at',
	taxReturnFull: 'Client had the tax credit for household expenses full',
	deciderMissing: 'I could not arrange both deciders there',
	rejectedFunding: 'Rejected funding',
};

export const notReachedStateReason = {
	noOneHomeNoAnswer: 'There was no one at home / did not answer the phone',
	other: 'Other',
};

export const condoContactedStateReason = {
	noNeed: 'No need',
	needNoVisit: 'Need found, could not book visit',
	arrangeReview: 'There is need, survey visit within 1-3 months',
	wantedContactInAMonth: 'Call within 1 month',
};

export const requestSource = {
	management: 'Administration',
	email: 'E-mail',
	instagram: 'Instagram',
	facebook: 'Facebook',
	website: 'Homepage',
	salesmanTip: 'Salesperson lead',
	installerTip: 'Installer lead',
	customerTip: 'Client lead',
	oldCustomer: 'Old client',
	googleAds: 'Google Ads',
	googleDisplay: 'Google Display',
	installationPresentation: 'Installation shows',
	campaigns: 'Campaigns',
	other: 'Other',
};

// extra sources for marketing leads only
export const extraRequestSource = {
	'facebook-instagram': 'Facebook-Instagram',
};
