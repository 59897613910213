import {evolve} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';

export const parseUrlQuery = query => {
	const vals = evolve({trashed: v => v === 'true'}, query);
	return replace(vals, initState.reasonMappingsQuery);
};

export const formatReasonMappingFormOutput = form => {
	const values = {
		...form,
		products: (form?.products || []).map(p => ({
			productId: p.id,
			preferredEncounterState: p.preferredEncounterState,
		})),
		tags: (form?.tags || []).map(t => ({id: t})),
	};
	return values;
};
