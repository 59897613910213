import {effect} from 'utils/redux';
import namespace from './namespace';
import * as actions from './actions';
import {catchNonFatalDefault} from 'io/errors';
import * as commonSelectors from 'modules/common/selectors';
import {appName} from '../constants';
import {ensureAccess, setPageTitleMessage, decorateWithNotifications} from 'io/app';
import {createReferrerUrl, encodeQuery} from 'utils/url';
import services from 'services';
import msgs from 'dicts/messages';
import {getCategories, getChannels, getCustomerTypes, getSources, deleteLead} from './io';

const creator = effect(namespace);
const history = services.get('history');
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const fetchMarketingSettings = (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'get-initial-marketing-settings',
			failureStyle: 'warning',
		},
		Promise.all([
			getCategories().then(categories => dispatch(actions._setCategories(categories))),
			getChannels().then(channels => dispatch(actions._setChannels(channels))),
			getCustomerTypes().then(types => dispatch(actions._setCustomerTypes(types))),
			getSources().then(sources => dispatch(actions._setSources(sources))),
		])

			.catch(e => {
				dispatch(actions._opFailed());
				throw e;
			})
			.then(data => {
				dispatch(actions._initialize(data));
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

export let initialize = () => (getState, dispatch) => {
	setPageTitleMessage('Marketing [app]')(getState, dispatch);

	ensureAccess(appName, commonSelectors.user(getState()))(getState, dispatch)
		.then(() => {
			fetchMarketingSettings(getState, dispatch);
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
initialize = creator('initialize', initialize);

export let createLead = () => (getState, dispatch) => {
	const referrerUrl = createReferrerUrl(history.location);
	history.push(`/marketing/create${encodeQuery({referrerUrl, disableReturn: true})}`);
};
createLead = creator('creatLead', createLead);

export let editLead =
	({id, referrer}) =>
	(getState, dispatch) => {
		history.push(`/marketing/lead/${id}${referrer}`);
	};
editLead = creator('editLead', editLead);

export let removeLead =
	({lead, deletedReason, otherReason}) =>
	(getState, dispatch) => {
		const reason = otherReason ? otherReason : deletedReason;
		const onConfirmed = () => {
			decorateWithNotifications(
				{
					id: 'delete-lead',
					failureStyle: 'error',
					loading: intl.formatMessage({id: msgs.processing}),
					success: intl.formatMessage({id: 'Lead removed'}),
				},
				deleteLead({id: lead.id, deletedReason: reason}),
			)(getState, dispatch)
				.catch(e => {
					dispatch(actions._opFailed());
					throw e;
				})
				.then(() => {
					dispatch(actions._removedLead());
				})
				.catch(catchNonFatalDefault(getState, dispatch));
		};

		onConfirmed();
	};
