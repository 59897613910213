import {effect} from 'utils/redux';
import namespace from './namespace';
import cookies from 'js-cookie';
import services from 'services';
import createIntl from 'services/createIntl';
import createPusher from 'services/createPusher';
import {P} from 'utils/types';
import {pick} from 'ramda';
import {
	handleAsFatal,
	logInfo,
	logWarning,
	catchHandled,
	warn,
	catchNonFatalDefault,
} from 'io/errors';
import {describeError, describeThrow} from 'utils/errors';
import {isApiTokenError} from 'utils/app';
import {getUserLanguage} from 'utils/loc';
import {messages} from 'constants/loc';
import {longestDur, shortDur} from 'constants/notifications';
import {
	getUser,
	getPersonalNotices as ioGetPersonalNotices,
	fetchReasonMappings as fetchReasonMappingsIo,
	postPersonalNoticeSeen,
	postAllPersonalNoticesSeen,
	postImpersonateUser,
	postStartTimer,
	postStopTimer,
	postFeedback,
	getApiKeys,
	enioChangeOrganization,
	enioGetCredentials,
	getActiveCallPools as getActiveCallPoolsIo,
	searchCallLogs,
	getProducts as getProductsIo,
} from './io';
import * as actions from './actions';
import * as nActions from 'modules/notifications/actions';
import {redirect as loginRedirect} from 'modules/login/selectors';
import {defaultOrganizationId} from 'constants/domain';
import * as selectors from 'modules/common/selectors';
import {decorateWithNotifications} from 'io/app';
import msgs from 'dicts/messages';
import importSoittolinja from 'services/importSoittolinja';
import importLeaddeskTalk from 'services/importLeaddeskTalk';
import {appVersion, defaultColors} from 'constants/app';
import {setCrossSiteCookie} from 'utils/cookies';
import {hexColorToRgb} from 'utils/math';
import {organizationMapPrimaryColors} from 'styles/constants';
import {enioCallClient as enioCallClientStable} from 'io/eniocaller/stable/calls';
import {enioCallClient as enioCallClientBeta} from 'io/eniocaller/beta/calls';
import {isPilotUser} from 'utils/perms';
import {endOfDay, startOfDay} from 'date-fns';
import {somicCallClient} from 'io/somic/stable/calls';
import {createReferrerUrl, encodeQuery} from 'utils/url';

const creator = effect(namespace);

let pusher = null;
services.waitFor('pusher').then(x => (pusher = x));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const history = services.get('history');

let appUpdateNumber = 0;

const setupChannels = (getState, dispatch) => {
	const currentUser = selectors.user(getState());

	const organizations = pusher.subscribe('private-organizations');

	organizations.bind(`client-${currentUser.id}:organizationChanged`, ({id}) => {
		if (id !== selectors.activeOrganizationId(getState())) {
			setOrganizationData(getState, id);
		}
	});

	const appUpdates = pusher.subscribe('app-updates');
	appUpdates.bind('updated', ({latest, critical}) => {
		if (appVersion !== latest) {
			dispatch(
				actions._addPersonalNotice({
					id: `app-updated-${appUpdateNumber++}`,
					type: 'appUpdate',
					createdAt: new Date().toISOString(),
					title: 'New app version available',
					seen: 0,
					content: intl.formatMessage({
						id: 'Click the link below to update to the new version.',
					}),
				}),
			);
			const personalNotices = selectors.personalNotices(getState());
			const nAmount = personalNotices
				? personalNotices.filter(n => n.seen !== 1).length
				: 0;
			if (nAmount > 0) {
				dispatch(
					nActions.info({
						id: 'personal-notices',
						message: intl.formatMessage(
							{id: `You have {count} unread notifications`},
							{count: nAmount},
						),
						duration: shortDur,
					}),
				);
			}

			if (critical) {
				// eslint-disable-next-line no-alert
				alert(
					intl.formatMessage({
						id: 'Critical app update available. Refresh the page as soon as you can.',
					}),
				);
			}
		}
	});
};

const clearStoredLogin = () => {
	cookies.remove('api_token', {domain: window.location.hostname});
};

const setOrganizationData = (getState, id) => {
	setCrossSiteCookie('organization_id', id, {expires: 365});
	const redirectUrl = selectors.organizationChangeRedirect(getState());
	if (redirectUrl != null) {
		window.location.assign(redirectUrl);
	} else {
		window.location.reload();
	}
};

const selectActiveOrganization = user => (getState, dispatch) => {
	const cookieOrgId = cookies.get('organization_id');
	const preferredOrgId = cookieOrgId ? Number(cookieOrgId) : defaultOrganizationId;

	// prettier-ignore
	const orgId = user.organizations.find(o => o.id === preferredOrgId) ?
		preferredOrgId
	: user.organizations.length ?
		user.organizations[0].id
	:
		null;

	if (!orgId) {
		const e = describeError(
			intl.formatMessage({
				id: 'You cannot use the application because you are not added to any organization',
			}),
			new Error('No organizations'),
		);

		// log the user back out since they had no organizations
		dispatch(actions._clearLoginData());
		clearStoredLogin();

		warn(nActions.warning, {id: 'no-orgs', duration: longestDur}, e)(getState, dispatch);

		return Promise.reject(e);
	}

	dispatch(actions._setActiveOrganizationId(orgId));

	setCrossSiteCookie('organization_id', orgId, {expires: 365});
	if (user.defaultCall === 'enioCaller') {
		enioChangeOrganization().catch(err => console.error(err));
		// At the moment this code is kinda useless. It will be used later.
		// enioGetOrganization()
		// 	.catch(err => console.error(err))
		// 	.then(res => {
		// 		console.log(res);
		// 	});
	}
	// perform redirect if the /login route with a redirect url was used with the Login module.
	// note: this is _not_ nice - the common module shouldn't depend on other modules, and the login module might not even be alive at this point. also this could probably be done earlier (no need to wait for org id cookie to be selected first?), even in the login module itself. not worth risking bugs by refactoring at this point though
	const redirectUrl = loginRedirect(getState());
	if (redirectUrl != null) {
		window.location.assign(redirectUrl);
	}

	return Promise.resolve(user);
};

const afterApiTokenReceival = apiToken => (getState, dispatch) => {
	return (
		getUser()
			.catch(describeThrow(messages['Could not load application data']))
			.catch(e => {
				// keep from crashing on invalid api token, handling is done in an api hook
				if (isApiTokenError(e)) {
					logInfo(e);
					throw e;
				}
				return handleAsFatal(e);
			})
			.then(user => {
				const bugsnag = services.get('bugsnag');
				if (bugsnag) {
					bugsnag.user = pick(['id', 'firstName', 'lastName', 'email'], user);
				}
				services.set('intl', createIntl({language: getUserLanguage(user)}));

				// Initialize organization map colors in constants
				const orgMapColors = {};
				for (const org of user.organizations) {
					const baseColor = org.meta.companyColors?.bg
						? org.meta.companyColors.bg
						: defaultColors.bg;
					let rgbVals = [255, 255, 255];
					try {
						rgbVals = hexColorToRgb(baseColor);
					} catch (e) {
						console.warn(
							`Invalid organization background color - organization ${org.title}`,
						);
					}
					// These colors are the same as regular organization colors but more transparent
					const rgba = [...rgbVals, '0.5'].join(',');
					orgMapColors[org.id] = `rgba(${rgba})`;
				}
				organizationMapPrimaryColors.current = orgMapColors;

				return user;
			})
			.then(user => selectActiveOrganization(user)(getState, dispatch))
			// We need teams for the current user (under given organization)
			.then(_user => getUser())
			.then(user =>
				getApiKeys().then(apiKeys => {
					dispatch(actions._setApiKeys(apiKeys));

					services.set('pusher', createPusher(apiToken, apiKeys.PUSHER_API_KEY));

					// import leaddesk talk api immediately after init - this improves responsivity, and if you try to import the api right before calling, leaddesk may not even work.
					// don't try to import if the user doesn't have this set as their call method (iframe won't be present)
					if (user.defaultCall === 'leaddeskTalk') {
						importLeaddeskTalk();
					}
					return user;
				}),
			)
			.then(user => {
				dispatch(actions._setUser(user));
				enioGetCredentials(user.id).then(enioDetails => {
					dispatch(actions._setEnioCallerDetails(enioDetails));
				});
				setupChannels(getState, dispatch);

				// import Soittolinja-plugin if it's user's defaultCall app
				if (user.defaultCall === 'soittolinja') {
					importSoittolinja().catch(logWarning);
				}

				// clear startNewTimer flag
				const startNewTimer = sessionStorage.getItem('startNewTimer');
				if (startNewTimer) {
					sessionStorage.removeItem('startNewTimer');
				}

				return Promise.all([
					fetchPersonalNotices({userId: user.id, keepOld: false})(getState, dispatch),
					fetchReasonMappings()(getState, dispatch),
					getActiveCallPools()(getState, dispatch),
					// starting new timer will stop the current one, no need to postStopTimer
					// startNewTimer has the timer type value which we need to send to _startNewTimer
					startNewTimer
						? _startTimer(startNewTimer)(getState, dispatch)
						: Promise.resolve(null),
				]);
			})
			.catch(catchHandled)
	);
};

export let initialize = () => (getState, dispatch) => {
	const apiToken = cookies.get('api_token') || null;
	dispatch(actions._setApiToken(apiToken));
	if (apiToken) {
		afterApiTokenReceival(apiToken)(getState, dispatch);
	}
	//const CallBroadcast = new BroadcastChannel('call');
	//const callState = getState();
};
initialize = creator('initialize', initialize);

export let setOrganization = id => (getState, dispatch) => {
	const currentUser = selectors.user(getState());

	const organizations = pusher.channel('private-organizations');
	organizations.trigger(`client-${currentUser.id}:organizationChanged`, {id});

	// add startNewTimer flag to sessionStorage if timer is currently active
	// this flag will start new timer for the other organization (and stop the current one) after org changed
	// set active timer type into startNewTimer
	if (selectors.activeTimerRunning(getState())) {
		sessionStorage.setItem('startNewTimer', selectors.activeTimerRunning(getState()));
	}

	setOrganizationData(getState, id);
};
setOrganization = creator('setOrganization', setOrganization, P.Number);

export let afterLogin = apiToken => (getState, dispatch) => {
	setCrossSiteCookie('api_token', apiToken, {});

	if (window.location.pathname.includes('/auth/callback/azure')) {
		const redirectUrl = localStorage.getItem('redirectUrl')
			? localStorage.getItem('redirectUrl')
			: '/';
		window.location.assign(redirectUrl);
		localStorage.removeItem('redirectUrl');
	}
	afterApiTokenReceival(apiToken)(getState, dispatch);
};
afterLogin = creator('afterLogin', afterLogin, P.String);

export let clearLoginData = () => (getState, dispatch) => {
	clearStoredLogin();
};
clearLoginData = creator('clearLoginData', clearLoginData);

const fetchPersonalNotices =
	({userId, _page, keepOld}) =>
	(getState, dispatch) => {
		const query = {userId, _page};

		return decorateWithNotifications(
			{id: 'get-personal-notices', failureStyle: 'warning'},
			ioGetPersonalNotices(query),
		)(getState, dispatch).then(({data, pagination}) => {
			dispatch(actions._setPersonalNotices({data, pagination, keepOld}));
		});
	};

export let setNoticeSeen =
	({id, metaField, showLoading = false}) =>
	(getState, dispatch) => {
		const userId = selectors.user(getState()).id;
		decorateWithNotifications(
			{
				id: 'set-notice-seen',
				failureStyle: 'warning',
				loading: showLoading ? intl.formatMessage({id: msgs.processing}) : null,
			},
			postPersonalNoticeSeen(userId, id, metaField),
		)(getState, dispatch)
			.then(_ => {
				dispatch(actions._setNoticeSeen(id));
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
setNoticeSeen = creator('setNoticeSeen', setNoticeSeen, P.Object);

export let setAllNoticesSeen = () => (getState, dispatch) => {
	const userId = selectors.user(getState()).id;
	decorateWithNotifications(
		{
			id: 'set-all-notices-seen',
			loading: intl.formatMessage({id: msgs.processing}),
			failureStyle: 'warning',
		},
		postAllPersonalNoticesSeen(userId),
	)(getState, dispatch)
		.then(() => {
			dispatch(actions._setAllNoticesSeen());
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
setAllNoticesSeen = creator('setAllNoticesSeen', setAllNoticesSeen);

export let impersonateUser = id => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'impersonate-user',
			loading: intl.formatMessage({id: msgs.loading}),
			failureStyle: 'error',
		},
		postImpersonateUser(id),
	)(getState, dispatch)
		.then(res => {
			if (res.token) {
				const apiToken = res.token;
				setCrossSiteCookie('api_token', apiToken, {});
				dispatch(actions._setApiToken(apiToken));
				return afterApiTokenReceival(apiToken)(getState, dispatch).then(() =>
					history.push('/'),
				);
			}
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};
impersonateUser = creator('impersonateUser', impersonateUser, P.Number);

// time entry effects
const _startTimer = type => (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'start-timer', failureStyle: 'error'},
		postStartTimer(type),
	)(getState, dispatch).then(t => {
		dispatch(actions._startTimer(t));
		dispatch(actions._setTimeEntries(t));
	});
};

export const startTimer = type => (getState, dispatch) => {
	_startTimer(type)(getState, dispatch)
		.catch(e => {
			dispatch(actions._stopProcessingTimeEntries());
			throw e;
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

export const stopTimer = () => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'stop-timer',
			failureStyle: 'error',
		},
		postStopTimer()
			.then(t => {
				dispatch(actions._stopTimer());
				dispatch(actions._setTimeEntries(t));
			})
			.catch(e => {
				dispatch(actions._stopProcessingTimeEntries());
				throw e;
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

export const startActiveTimer = () => (getState, dispatch) => {
	const existingTimerId = selectors.activeTimerId(getState());
	if (!existingTimerId) {
		const timerId = setInterval(() => {
			dispatch(actions._updateTotalTime());
		}, 60000);
		dispatch(actions._setActiveTimerId(timerId));
	}
};

export const clearActiveTimer = () => (getState, dispatch) => {
	const timerId = selectors.activeTimerId(getState());
	if (timerId) {
		clearInterval(timerId);
		dispatch(actions._setActiveTimerId(null));
	}
};

export const createFeedback = form => (getState, dispatch) => {
	decorateWithNotifications(
		{
			id: 'create-feedback',
			failureStyle: 'error',
			loading: intl.formatMessage({id: msgs.processing}),
			success: intl.formatMessage({id: 'Thank you for the feedback!'}),
		},
		postFeedback(form)
			.then(f => {
				dispatch(actions._feedbackSaved());
			})
			.catch(e => {
				dispatch(actions._stopProcessingFeedback());
				throw e;
			}),
	)(getState, dispatch).catch(catchNonFatalDefault(getState, dispatch));
};

export const getPersonalNotices =
	({_page}) =>
	(getState, dispatch) => {
		const userId = selectors.user(getState()).id;
		fetchPersonalNotices({userId, _page, keepOld: true})(getState, dispatch).catch(
			catchNonFatalDefault(getState, dispatch),
		);
	};

export const fetchReasonMappings = () => async (getState, dispatch) => {
	return decorateWithNotifications(
		{id: 'get-reason-mappings', failureStyle: 'warning'},
		fetchReasonMappingsIo(),
	)(getState, dispatch)
		.catch(catchNonFatalDefault(getState, dispatch))
		.then(reasons => {
			dispatch(actions.setReasonMappings(reasons || []));
		});
};

export let makeCall = callDetails => (getState, dispatch) => {
	const {user, number} = callDetails;
	if (!user) return;
	// TODO: interface for this
	const callClient = somicCallClient;

	decorateWithNotifications(
		{
			id: 'custom-call',
			failureStyle: 'error',
		},
		callClient({
			client: {id: null, phone: number},
			building: {id: null},
			user: user,
			appName: 'numpad',
		}),
	);
};

export let makeEnioCall = callDetails => (getState, dispatch) => {
	const {user, number} = callDetails;
	if (!user) return;
	const enioCallClient = isPilotUser(user) ? enioCallClientBeta : enioCallClientStable;

	decorateWithNotifications(
		{
			id: 'enio-custom-call',
			failureStyle: 'error',
		},
		enioCallClient({
			client: {id: null, phone: number},
			building: {id: null},
			user: user,
			appName: 'enioNumpad',
		}),
	);
};

makeEnioCall = creator('makeEnioCall', makeEnioCall);

export let getActiveCallPools = () => (getState, dispatch) => {
	return getActiveCallPoolsIo()
		.then(({data}) => {
			dispatch(actions._getActiveCallPools(data));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

getActiveCallPools = creator('getActiveCallPools', getActiveCallPools);

export let refreshDailyCallDuration = () => (getState, dispatch) => {
	const query = {
		createdAtStart: startOfDay(new Date()).toISOString(),
		createdAtEnd: endOfDay(new Date()).toISOString(),
		users: [selectors.user(getState()).id],
	};
	return searchCallLogs(query)
		.then(data => {
			const durationInSeconds = (data ?? []).reduce((acc, {duration}) => {
				return acc + parseInt(duration);
			}, 0);

			dispatch(actions._refreshDailyCallDuration(durationInSeconds));
		})
		.catch(catchNonFatalDefault(getState, dispatch));
};

refreshDailyCallDuration = creator('refreshDailyCallDuration', refreshDailyCallDuration);

export let searchProducts =
	({callback}) =>
	(getState, dispatch) => {
		decorateWithNotifications(
			{
				id: 'search-products',
				failureStyle: 'warning',
			},
			getProductsIo({_limit: 999}),
		)(getState, dispatch)
			.then(products => {
				callback(products);
			})
			.catch(catchNonFatalDefault(getState, dispatch));
	};
searchProducts = creator('searchProducts', searchProducts);

export let navigateToLeadInTeamCalendar = lead => (getState, dispatch) => {
	const activeOrganizationId = selectors.activeOrganizationId(getState());
	const {buildingId, clientId} = lead;

	const referrerUrl = createReferrerUrl(history.location);

	const url = `/team-calendar/calendar${encodeQuery({
		buildingId,
		clientId,
		referrer: 'marketing',
		referrerUrl,
		leadId: lead.id,
	})}`;

	if (activeOrganizationId !== lead.organizationId && lead.organizationId) {
		// Change organization before navigating to calls if activeOrganizationId is not same as lead's organizationId
		dispatch(actions.setOrganizationChangeRedirect(url));
		dispatch(actions.setOrganization(lead.organizationId));
	} else {
		window.location.assign(url);
	}
};
navigateToLeadInTeamCalendar = creator(
	'navigateToLeadInTeamCalendar',
	navigateToLeadInTeamCalendar,
);
