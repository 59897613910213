import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';
import {
	formatSalesmanSearchOutput,
	formatLeadFormOutput,
	formatLeadFormInput,
	parseAddBuildingFormInitValues,
	formatAddBuildingOutput,
	formatClientOutput,
	formatClient,
} from './utils';
import {getFormValues, change} from 'redux-form';
import * as normalize from 'utils/normalize';
import {pipe, map, prop, isEmpty} from 'ramda';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize(payload)];
		}

		case actions.reInitialize.type: {
			const id = payload ? payload : null;
			return [
				{
					...state,
					processing: true,
					leadFormInitialValues: formatLeadFormInput({}),
					loadingLeadForm: true,
				},
				effects.initialize(id),
			];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.saveLead.type: {
			const form = getFormValues('leadForm')(fullState);
			const data = formatLeadFormOutput({form, salesmanagers: state.salesmanagers});
			return [
				{...state, processing: true, loadingLeadForm: true},
				effects.saveLead({form: data, navigateToTeamCalendar: payload}),
			];
		}

		case actions.restoreLead.type: {
			return [{...state, processing: true}, effects.restoreLead(payload)];
		}

		case actions.searchBuildings.type: {
			return [state, effects.searchBuildings(payload)];
		}

		case actions.searchSalesmanagers.type: {
			const form = getFormValues('leadForm')(fullState) || null;
			const query = formatSalesmanSearchOutput({form});
			return [{...state, processing: true}, effects.searchSalesmanagers(query)];
		}

		case actions.openAddBuildingModal.type: {
			// parse initial address and zip from search string
			const addBuildingFormInitValues = parseAddBuildingFormInitValues(payload);

			return [
				{
					...state,
					addBuildingModalOpen: true,
					addBuildingFormInitValues,
				},
				null,
			];
		}

		case actions.closeAddBuildingModal.type: {
			return [
				{
					...state,
					addBuildingModalOpen: false,
					addBuildingFormInitValues: initState.addBuildingFormInitValues,
				},
				null,
			];
		}

		case actions.openMapModal.type: {
			return [
				{...state, mapModalOpen: true, buildingToAdd: payload},
				effects.initAddBuildingMap(),
			];
		}

		case actions._setBuildingToAddCoords.type: {
			return [{...state, buildingToAdd: {...state.buildingToAdd, coords: payload}}, null];
		}

		case actions.closeMapModal.type: {
			return [
				{...state, mapModalOpen: false, buildingToAdd: initState.buildingToAdd},
				null,
			];
		}

		case actions.addBuilding.type: {
			const building = formatAddBuildingOutput(state.buildingToAdd);
			return [{...state, processing: true}, effects.addBuilding(building)];
		}

		case actions.addClientToBuilding.type: {
			const form = getFormValues('leadForm')(fullState) || null;
			const client = formatClientOutput({form});

			return [{...state, processing: true}, effects.createClient(client)];
		}

		case actions._addClient.type: {
			const clients = [...state.clients, payload];
			return [{...state, clients, processing: false}, null];
		}

		case actions._addBuilding.type: {
			return [
				{
					...state,
					processing: false,
					mapModalOpen: false,
					addBuildingModalOpen: false,
					buildingToAdd: initState.buildingToAdd,
					addBuildingFormInitValues: initState.addBuildingFormInitValues,
					clients: initState.clients,
				},
				null,
			];
		}

		case actions.setBuilding.type: {
			const form = getFormValues('leadForm')(fullState) || null;
			const {products} = form;
			const building = payload;

			const clients = pipe(prop('data'), map(normalize.clients))(building.clients);

			return [
				{...state, clients},
				!products?.length
					? effects.setFormValues(change('leadForm', 'clientId', ''))
					: [
							effects.searchSalesmanagers({buildingId: building.id, products}),
							effects.setFormValues(change('leadForm', 'clientId', '')),
					  ],
			];
		}

		case actions.setProcessing.type: {
			return [{...state, processing: payload}, null];
		}

		case actions.updateClientData.type: {
			const _client = getFormValues('leadForm')(fullState).client || null;
			const lead = state.lead;
			const client = formatClient({client: _client, lead});
			return [{...state, processing: true}, effects.updateClient({client})];
		}

		case actions._clientSaved.type: {
			return [{...state, processing: false}, null];
		}

		case actions._initialize.type: {
			const allSalesmanagers = payload[0].data;
			const products = payload[1].data;
			const lead = payload[3] ? payload[3] : {};
			const clients = lead && lead.building ? lead.building.clients : [];
			const organizations = payload[2];
			const leadFormInitialValues = formatLeadFormInput({lead, clients});
			return [
				{
					...state,
					initialized: true,
					products,
					allSalesmanagers,
					lead,
					clients,
					organizations,
					leadFormInitialValues,
					loadingLeadForm: false,
				},
				null,
			];
		}

		case actions._opFailed.type: {
			return [{...state, processing: false, loadingLeadForm: false}, null];
		}

		case actions._setBuildings.type: {
			const buildings = payload;
			return [{...state, buildings, loadingBuildings: false}, null];
		}

		case actions._setSalesmanagers.type: {
			const leadSalesmanager = !isEmpty(state.lead) ? state.lead.salesmanager : null;
			const userInSalesmanagers =
				leadSalesmanager && payload.data
					? payload.data.find(x => x.id === leadSalesmanager.id)
					: false;

			const salesmanagers =
				!isEmpty(payload) && !isEmpty(payload.data)
					? !isEmpty(state.lead) && !userInSalesmanagers && leadSalesmanager
						? [...payload.data, leadSalesmanager]
						: payload.data
					: state.allSalesmanagers;

			const newState = {
				...state,
				salesmanagers,
				processing: false,
			};

			return [newState, null];
		}

		case actions._setProducts.type: {
			return [{...state, products: payload.data}, null];
		}

		case actions._setCategories.type: {
			return [{...state, marketingCategories: payload, categoriesLoading: false}, null];
		}
		case actions._setCustomerTypes.type: {
			return [
				{...state, marketingCustomerTypes: payload, customerTypesLoading: false},
				null,
			];
		}
		case actions._setSources.type: {
			return [{...state, marketingSources: payload, sourcesLoading: false}, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
