import {sortWith, descend, prop} from 'ramda';
import {visitFormBase} from './constants';
import {processProductStatesForForms} from 'utils/products';
import {toLocaleDateString, increaseDay, getHourBoundaries} from 'utils/time';
import {getNextDayEncounterSuggestion} from 'utils/encounters';
import {
	formatEncounterFormFillFormOutput,
	formatFormCalendarResourceOutput,
	formatFormBonusCalendarResourceOutput,
} from 'utils/formData';
import {pickIfNot} from 'utils/objects';
import {ihSource} from 'dicts/calendarResources';
import {decodeQuery} from 'utils/url';

// Clients form
export const formatClientFormInput = ({client}) => {
	return client;
};
export const formatClientFormOutput = ({form}) => {
	const client = {...form, emailMarketing: form.emailMarketing ? new Date() : null};
	return client;
};

export const processProductStates = processProductStatesForForms;

// NOTE: similar to Calls
export const formatVisitFormInput = ({userTeams, openedAt, clients}) => {
	const sortedClients = sortWith([descend(prop('createdAt'))])(clients);
	const contactClientId = sortedClients.length ? sortedClients[0].id : null;

	return {
		...visitFormBase,
		calendarResource: {
			...visitFormBase.calendarResource,
			teamId: userTeams.length ? userTeams[0].id : null,
			dateFrom: openedAt ? getNextDayEncounterSuggestion(openedAt) : null,
		},
		bonusCalendarResource: {
			...visitFormBase.bonusCalendarResource,
			dateFrom: openedAt ? toLocaleDateString(increaseDay(openedAt, 1)) : null,
		},
		contactClientId,
	};
};

export const formatVisitFormOutput = ({form, building, teamId}) => {
	const {
		dateCreatorOpen,
		state,
		isBonus,
		calendarResource,
		bonusCalendarResource,
		contactClientId,
		reason,
		reasonMappingId,
		formFill,
		...visitVals
	} = form;

	const isVisit = state === 'visit';
	const isLost = reason === 'allRepairsDone';

	const visitData = {
		...visitVals,
		contactClientId,
		// keep fields if they match criteria, otherwise clear them to base values
		...pickIfNot(
			{
				calendarResourceId: isVisit && !dateCreatorOpen,
				contactClientId: isVisit || (!isVisit && isBonus),
			},
			visitFormBase,
		),
		appointment: isVisit,
		reached: true,
		preferredEncounterState: isLost ? 'cancelled' : !isVisit ? 'contacted' : null,
		reason: isVisit ? null : reason,
		reasonMappingId: isVisit ? null : reasonMappingId,
	};

	const calendarResourceData =
		// prettier-ignore
		isVisit && dateCreatorOpen
			? formatFormCalendarResourceOutput({calendarResource, contactClientId})
		: !isVisit && isBonus
			? formatFormBonusCalendarResourceOutput({bonusCalendarResource, contactClientId, teamId})
		: null;

	const formFillData = isVisit ? formatEncounterFormFillFormOutput(formFill, true) : null;

	return {
		visit: visitData,
		calendarResource: calendarResourceData,
		formFill: formFillData,
	};
};

export const getDefaultIhSource = ({allEncounters, referrer, referrerUrl}) => {
	if (allEncounters && allEncounters.find(e => e.state === 'installed')) {
		return 'installed';
	} else if (!allEncounters || !allEncounters.length) {
		return 'notContacted';
	} else if (referrer === 'freeride') {
		return 'd2d';
	} else if (referrer === 'salesman-app-contacts' && referrerUrl) {
		// read the selected encounterState (in SalesmanApp search form) from referrerUrl
		const {encounterState} = decodeQuery(referrerUrl);
		const isValidIhSource = encounterState
			? Object.keys(ihSource).includes(encounterState)
			: false;

		return isValidIhSource ? encounterState : null;
	}

	return null;
};

export const formatSalesVisitFormInput = ({visitFormContactClientId, clients}) => {
	return {
		dateFrom: new Date(),
		clientId: visitFormContactClientId
			? visitFormContactClientId
			: clients && clients.length
			? clients[0].id
			: null,
	};
};

export const formatSalesVisitFormOutput = ({form, buildingId, userId, teamId}) => {
	const {dateFrom, clientId} = form;
	const bounds = getHourBoundaries(dateFrom);

	const calendarResource = {
		dateFrom: bounds[0],
		dateTo: bounds[1],
		appointmentType: 'visit',
		ihSource: 'd2d',
		buildingId,
		clientId,
		reserverId: userId,
		salesmanId: userId,
		teamId,
	};

	return {
		calendarResource,
	};
};
