import {leadFormInitialValues} from './constants';
import {isEmpty} from 'ramda';
import {transform} from 'ol/proj';
import {formatLocalDateInput, formatLocalDateOutput} from 'utils/fields';

export const formatSalesmanSearchOutput = ({form}) => {
	const searchQuery = {
		products: form.products,
		zip: form.buildingNotFound ? form.zip : null,
		buildingId: !form.buildingNotFound && form.building ? form.building.id : null,
	};

	return searchQuery;
};

export const formatLeadFormOutput = ({form, salesmanagers}) => {
	const salesmanager = salesmanagers.find(s => s.id === form.salesmanager);

	const data = {
		buildingId: !form.buildingNotFound && form.building ? form.building.id : null,
		clientId:
			!form.buildingNotFound && !form.clientNotFound && form.clientId
				? form.clientId
				: null,
		products: form.products ? form.products.map(id => ({id})) : null,
		salesmanagerId: form.salesmanager ? form.salesmanager : null,
		zip: form.buildingNotFound && form.zip ? form.zip : null,
		city: form.buildingNotFound && form.city ? form.city : null,
		address: form.buildingNotFound ? form.address : null,
		clientFirstName:
			form.clientNotFound || form.buildingNotFound ? form.clientFirstName : null,
		clientLastName:
			form.clientNotFound || form.buildingNotFound ? form.clientLastName : null,
		phone: form.clientNotFound || form.buildingNotFound ? form.phone : null,
		comment: form.comment,
		organizationId: salesmanager
			? salesmanager.organizations[0].id // TODO: How to handle if salesmanager has multiple/zero organizations?
			: form.organizationId,
		type: form.type,
		marketingSourceId: form?.marketingSourceId || null,
		marketingCategoryId: form?.marketingCategoryId || null,
		marketingCustomerTypeId: form?.marketingCustomerTypeId || null,
		url: form?.url || null,
	};

	return data;
};

export const formatLeadFormInput = ({lead = {}, clients}) => {
	const client = lead.clientId ? clients.find(c => c.id === lead.clientId) || {} : {};

	const initValues = isEmpty(lead)
		? leadFormInitialValues
		: {
				buildingNotFound: lead.buildingId ? false : true,
				clientNotFound: lead.clientId ? false : true,
				building: lead.buildingId ? lead.building : null,
				address: !lead.buildingId ? lead.address : '',
				zip: !lead.buildingId ? lead.zip : '',
				city: !lead.buildingId ? lead.city : '',
				clientId:
					lead.clientId && !lead.clientNotFound && !lead.buildingNotFound
						? lead.clientId
						: '',
				clientFirstName: lead.clientFirstName ? lead.clientFirstName : '',
				clientLastName: lead.clientLastName ? lead.clientLastName : '',
				phone: lead.phone ? lead.phone : '',
				email: lead.email ? lead.email : '',
				marketingAuthorization: lead.marketingAuthorization
					? formatLocalDateInput(new Date(lead.marketingAuthorization))
					: null,
				products: lead.products ? lead.products.map(p => p.id) : [],
				salesmanager: lead.salesmanagerId,
				comment: lead.comment ? lead.comment : '',
				type: lead.type ? lead.type : '',
				domain: lead.url,
				source: lead.utm_source,
				campaign: lead.utm_campaign,
				organizationId: lead.organizationId,
				class: lead.class,
				heardFrom: lead.heardFrom,
				message: lead.message,
				service: lead.service,
				client: {
					id: client.id,
					firstName: client.firstName,
					lastName: client.lastName,
					phone: client.phone,
					email: client.email,
					emailMarketing: client.emailMarketing ? client.emailMarketing : null,
				},
				marketingSourceId: lead?.marketingSourceId || null,
				marketingCategoryId: lead?.marketingCategoryId || null,
				marketingCustomerTypeId: lead?.marketingCustomerTypeId || null,
				url: lead?.url || null,
		  };

	return initValues;
};

export const formatClientOutput = ({form}) => {
	const client = {
		buildingId: form.building.id,
		firstName: form.clientFirstName,
		lastName: form.clientLastName,
		phone: form.phone,
		email: form.email,
		marketingAuthorization: form.marketingAuthorization,
	};

	return client;
};

/*
copy pasted from teamcalendar
got to remove duplicates and make sure they are good for generic use for these kinds of uses
*/
export const parseAddBuildingFormInitValues = ({address, zip}) => {
	if (!address && !zip) return {address: '', zip: ''};

	return {
		address,
		zip,
	};
};

export const formatAddBuildingOutput = building => {
	const transformedCoords = transform(building.coords, 'EPSG:3857', 'EPSG:4326');

	return {
		address: building.address,
		zip: building.zip,
		type: building.type,
		manufacturingYear: building.manufacturingYear,
		location: {lng: transformedCoords[0], lat: transformedCoords[1]},
	};
};

export const formatClient = ({client, lead}) => {
	const _client = {
		...client,
		email: client.email ? client.email : lead.email,
		marketingAuthorization: client.emailMarketing
			? formatLocalDateOutput(new Date(client.emailMarketing))
			: null,
	};
	return _client;
};
