import {scopedHandler} from 'utils/redux';
import ns from './namespace';
import initState from './state';
import * as actions from './actions';
import * as effects from './effects';
import {decorateHandler as lifecycle} from 'fragments/lifecycle';

const handler = scopedHandler(ns, (state = initState, fullState, {type, payload}) => {
	switch (type) {
		case actions.initialize.type: {
			return [state, effects.initialize()];
		}

		case actions.destroy.type: {
			return [initState, null];
		}

		case actions.createLead.type: {
			return [state, effects.createLead()];
		}

		case actions.editLead.type: {
			return [state, effects.editLead(payload)];
		}

		case actions.openDeleteLeadModal.type: {
			return [{...state, deleteLeadModalOpen: true, leadToDelete: payload}, null];
		}

		case actions.closeDeleteLeadModal.type: {
			return [{...state, deleteLeadModalOpen: false, leadToDelete: null}, null];
		}

		case actions.removeLead.type: {
			return [state, effects.removeLead(payload)];
		}

		case actions._removedLead.type: {
			return [
				{...state, deleteLeadModalOpen: false, leadToDelete: null, processing: false},
				null,
			];
		}

		case actions._setCategories.type: {
			return [{...state, marketingCategories: payload, categoriesLoading: false}, null];
		}
		case actions._setChannels.type: {
			return [{...state, marketingChannels: payload, channelsLoading: false}, null];
		}
		case actions._setCustomerTypes.type: {
			return [
				{...state, marketingCustomerTypes: payload, customerTypesLoading: false},
				null,
			];
		}
		case actions._setSources.type: {
			return [{...state, marketingSources: payload, sourcesLoading: false}, null];
		}

		case actions._initialize.type: {
			return [{...state, initialized: true}, null];
		}

		case actions._opFailed.type: {
			return [state, null];
		}

		default:
			return [state, null];
	}
});

export default lifecycle({
	namespace: ns,
	initializeType: actions.initialize.type,
	destroyType: actions.destroy.type,
})(handler);
