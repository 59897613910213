import {map, prop, pipe, filter, ascend} from 'ramda';
import services from 'services';
import {describeThrow, describeError} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {mapResponseData, getResponseData} from 'utils/app';
import {salesmanagersQuery} from './constants';
import {sort} from 'utils/arrays';

import {apiUrl} from 'constants/app';
import importMaps from 'services/importMaps';
import {
	maxZoom,
	buildingsMinZoom,
	styles as mapStyles,
	buildingsYearMinZoom,
} from 'constants/maps';
import {
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderBuildingTooltip,
	createMarkerLayer,
	_getMapTileSource,
	_addMarker,
} from 'io/maps';
import {deleteChildren} from 'utils/dom';
import {getJsonProperties, concreteBuildingStyle} from 'utils/maps';
import {encodeQuery} from 'utils/url';
import {mapTooltip} from 'styles/fragments';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const getLead = id =>
	httpJson('get', `/leads/${id}`, {
		include:
			'user,building,client,building.clients,salesmanager.organizations,calendarResource.team,products',
	})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data'), normalize.lead));

export const getBuildings = query =>
	httpJson('get', `/buildings/search`, {include: 'clients', ...query})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to search buildings'})))
		.then(prop('data'));

export const getSalesmanagers = query =>
	httpJson('get', '/users', {
		...query,
		...salesmanagersQuery,
	})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to find sales managers'})))
		.then(mapResponseData(map(normalize.user)));

export const putClient = client =>
	httpJson('put', `/clients/${client.id}`, {}, {body: client})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save'})))
		.then(getResponseData(normalize.client));

// for the lead form product selector
export const getProducts = () =>
	httpJson('get', '/products/all', {
		_limit: 999,
		include: 'organizations',
	})
		.catch(describeThrow(intl.formatMessage({id: 'Product search failed'})))
		.then(
			mapResponseData(
				pipe(
					map(normalize.product),
					// drop products from customer orgs
					filter(p => p.organizations.find(o => !o.meta || !o.meta.customerOrganization)),
					// drop "other" type products
					filter(p => p.type !== 'other'),
					sort(ascend(p => p.title)),
				),
			),
		);

export const postLead = body =>
	httpJson('post', '/leads', {}, {body})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save lead [tip]'})))
		.then(getResponseData(normalize.lead));

export const putLead = (body, id) =>
	httpJson('put', `/leads/${id}`, {}, {body})
		.catch(describeThrow(intl.formatMessage({id: 'Failed to save lead [tip]'})))
		.then(pipe(prop('data'), normalize.lead));

export const postBuilding = building =>
	httpJson('post', '/buildings', {}, {body: building})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 409;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(
						describeError(intl.formatMessage({id: 'Failed to create building'}), e),
				  );
		})
		.then(getResponseData(normalize.building));

export const postClient = (client, buildingId) =>
	httpJson('post', '/clients', {}, {body: {buildingId, ...client}})
		.catch(e => {
			const conflict = !!e.response && e.response.status === 409;
			e.causedByMarketingPrevented = conflict;
			return conflict
				? e.response.json().then(body => describeThrow(body.message, e))
				: Promise.reject(describeError(msgs.saveFailed, e));
		})
		.then(getResponseData(normalize.client));

export const getOrganizations = () =>
	httpJson('get', '/organizations', {customerOrganizations: false})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(getResponseData(map(normalize.organization)));

export const postRestoreLead = lid =>
	httpJson('post', `/leads/${lid}/restore`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to restore'})),
	);

export const getCategories = _ =>
	httpJson('get', `/marketingCategories`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getCustomerTypes = _ =>
	httpJson('get', `/marketingCustomerTypes`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));

export const getSources = _ =>
	httpJson('get', `/marketingSources`, {}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(pipe(prop('data')));
// maps

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

const _getBuildingsStyle =
	({zoom}) =>
	imports => {
		const {openLayers: ol} = imports;

		const sty = mapStyles(ol);
		const keys = ['encounterState', 'manufacturingYear', 'encounterDate', 'banned'];

		return feature => {
			const {encounterState, manufacturingYear, encounterDate, banned} =
				getJsonProperties(feature, keys);

			const building = {encounterState, encounterDate, banned, manufacturingYear};

			return new ol.style.Style({
				...concreteBuildingStyle(ol, {
					geomType: feature.getGeometry().getType(),
					zoom,
					style: sty.buildingStyleProps({
						building,
					}),
				}),
				text:
					zoom >= buildingsYearMinZoom
						? new ol.style.Text({
								text: manufacturingYear ? `${manufacturingYear}` : '',
								overflow: true,
						  })
						: null,
			});
		};
	};
export const getBuildingsStyle = decorateMapMethod(_getBuildingsStyle);

const _getBuildingsSource =
	({apiToken, organizationId}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			// prettier-ignore
			url: `${apiUrl}/maps/buildings/mvt/{z}/{x}/{y}${encodeQuery({organizationId, token: apiToken})}`,
			maxZoom: maxZoom,
		});
	};
export const getBuildingsSource = decorateMapMethod(_getBuildingsSource);

const createBuildingsLayer =
	({apiToken, organizationId, initialZoom}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getBuildingsSource({apiToken, organizationId})(imports),
			style: _getBuildingsStyle({
				zoom: initialZoom,
			})(imports),
			visible: initialZoom >= buildingsMinZoom,
		});

		return layer;
	};

const _initAddBuildingMap =
	({apiToken, organizationId, initialZoom, initialCenter, onDragEnd}) =>
	imports => {
		// keep some local state
		let currHoverBuildingId = null;

		const {openLayers: ol} = imports;

		const mapEl = document.querySelector('#add-building-map');
		if (!mapEl) {
			return Promise.reject(new Error('Map element not found'));
		}

		const view = new ol.View({
			projection: 'EPSG:3857',
			center: initialCenter,
			zoom: initialZoom,
			maxZoom,
			enableRotation: false,
			constrainResolution: true,
		});

		const buildingsLayer = createBuildingsLayer({
			apiToken,
			organizationId,
			initialZoom,
		})(imports);

		const mapSource = _getMapTileSource({sourceId: 'here-streets'})(imports);
		const mapLayer = new ol.layer.Tile({source: mapSource});

		const markerLayer = createMarkerLayer(imports);

		const map = new ol.Map({
			target: 'add-building-map',
			view,
			layers: [mapLayer, buildingsLayer, markerLayer],
		});

		map.on('moveend', e => {
			const zoom = map.getView().getZoom();

			// toggle buildings layer
			if (zoom >= buildingsMinZoom) {
				buildingsLayer.setVisible(true);
			} else {
				buildingsLayer.setVisible(false);
			}
		});

		map.on('pointermove', e => {
			if (e.dragging) return;
			const pixel = map.getEventPixel(e.originalEvent);
			const hit = safeHasFeatureAtPixel(map, pixel, {
				layerFilter: l => l === buildingsLayer || l === markerLayer,
			});
			map.getViewport().style.cursor = hit ? 'pointer' : '';
		});

		const buildingTooltipEl = document.createElement('div');
		buildingTooltipEl.id = 'add-building-map-tooltip';
		buildingTooltipEl.style.cssText = mapTooltip;

		const buildingTooltip = new ol.Overlay({
			element: buildingTooltipEl,
			positioning: 'bottom-center',
			offset: [0, -5],
		});

		const updateBuildingTooltip = buildingFeature => {
			const {
				id,
				address,
				manufacturingYear,
				encounterState,
				encounterDate,
				clientId,
				clientFirstName,
				clientLastName,
			} = getJsonProperties(buildingFeature, [
				'id',
				'address',
				'manufacturingYear',
				'encounterState',
				'encounterDate',
				'clientId',
				'clientFirstName',
				'clientLastName',
			]);

			if (id === currHoverBuildingId) return;

			currHoverBuildingId = id;
			deleteChildren(buildingTooltipEl);
			renderBuildingTooltip({
				tooltipEl: buildingTooltipEl,
				address,
				manufacturingYear,
				encounterState,
				encounterDate,
				clientId,
				clientFirstName,
				clientLastName,
			});
		};

		addTooltipToLayers({
			mapEl,
			map,
			layers: [buildingsLayer],
			tooltipEl: buildingTooltipEl,
			tooltip: buildingTooltip,
			updateTooltip: updateBuildingTooltip,
		});

		_addMarker({
			id: 'new-building-marker',
			coord: map.getView().getCenter(),
			source: markerLayer.getSource(),
			map,
			draggable: true,
			onDragEnd,
		})(imports);

		return Promise.resolve({
			map,
			buildingsLayer,
			mapLayer,
			markerLayer,
		});
	};
export const initAddBuildingMap = decorateMapMethod(_initAddBuildingMap);
