import {descend, path, pipe, prop, sortWith} from 'ramda';
import {createSelector} from 'reselect';
import {formValueSelector} from 'redux-form';
import namespace from './namespace';
import {selectors as lifecycleSelectors} from 'fragments/lifecycle';
import {
	formatClientFormInput,
	formatVisitFormInput,
	formatSalesVisitFormInput,
} from './utils';
import {createEmptyClient} from './constants';
import createDatePickSelectors from 'fragments/calendarResourcePicker/selectors';
import createBuildingPanelSelectors from 'fragments/buildingModalActions/selectors';
import createReminderSelectors from 'fragments/callReminder/selectors';

const root = path(namespace);

export const {seq} = lifecycleSelectors(namespace);

const datePickSelectors = createDatePickSelectors({namespace});

export const {
	freeCalendarResources,
	pendingCalendarResourceSelection,
	lastCalendarResourceSelection,
} = datePickSelectors;

const buildingPanelSelectors = createBuildingPanelSelectors({namespace});

const reminderSelectors = createReminderSelectors({namespace});

export const {
	callReminder,
	callReminderFormInitValues,
	callReminderLoading,
	callReminderEditorOpen,
} = reminderSelectors;

export const {buildingModalOpen} = buildingPanelSelectors;

export const processing = pipe(root, prop('processing'));

export const building = pipe(root, prop('building'));

export const buildingLoading = pipe(root, prop('buildingLoading'));

export const clients = createSelector([building], building =>
	building && building.clients
		? sortWith([descend(prop('createdAt'))])(building.clients)
		: [],
);

export const initialized = pipe(root, s => !s.buildingLoading);

export const encounters = pipe(root, prop('encounters'));

export const encountersLoading = pipe(root, prop('encountersLoading'));

export const userTeams = pipe(root, prop('userTeams'));

export const userTeamsLoading = pipe(root, prop('userTeamsLoading'));

export const salesTeams = pipe(root, prop('salesTeams'));

export const salesTeamsLoading = pipe(root, prop('salesTeamsLoading'));

export const productStates = pipe(root, prop('productStates'));

export const visitJustSaved = pipe(root, prop('visitJustSaved'));

export const openedAt = pipe(root, prop('openedAt'));

export const marketingLeadSources = pipe(root, prop('marketingLeadSources'));

export const visitFormInitValues = createSelector(
	[userTeams, openedAt, clients],
	(userTeams, openedAt, clients) => formatVisitFormInput({userTeams, openedAt, clients}),
);

export const freeCalendarResourcesLoading = pipe(
	root,
	prop('freeCalendarResourcesLoading'),
);

export const salesVisitModalOpen = pipe(root, prop('salesVisitModalOpen'));

export const visitFormContactClientId = state =>
	formValueSelector('visitForm')(state, 'contactClientId');

export const salesVisitFormInitValues = createSelector(
	[visitFormContactClientId, clients],
	(visitFormContactClientId, clients) =>
		formatSalesVisitFormInput({visitFormContactClientId, clients}),
);

export const products = pipe(root, prop('products'));

// encounter modal
export const encounter = pipe(root, prop('encounter'));

// client modal
export const client = pipe(root, prop('client'));

export const clientEditorOpen = pipe(root, prop('clientEditorOpen'));

export const newClientEditorOpen = pipe(root, prop('newClientEditorOpen'));

export const clientFormInitValues = createSelector(
	[client, building],
	(client, building) =>
		formatClientFormInput({
			client: client || createEmptyClient(),
		}),
);
export const availableTags = pipe(root, prop('availableTags'));

export const tags = pipe(root, prop('tags'));

export const processingTags = pipe(root, prop('processingTags'));

export const processingTag = pipe(root, prop('processingTag'));
