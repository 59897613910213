import React from 'react';
import {FormattedMessage} from '@meiko/react-intl';
import Label from 'components/generic/Label';
import FormBlock from 'components/generic/FormBlock';
import ReactSelect from 'components/generic/ReactSelect';
import FieldError from 'components/generic/FieldError';
import PropTypes from 'prop-types';
import {FieldArray, Field as ReduxField} from 'redux-form';
import {state as productState} from 'dicts/products';
import Select from 'components/generic/Select';
import services from 'services';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

const ProductStateFormlet = ({name, label = null, searchProducts = () => null}) => {
	const [cachedProducts, setCachedProducts] = React.useState([]);
	const inputId = `field-${name}`;

	return (
		<FieldArray
			name={name}
			inputId={inputId}
			component={RenderFieldArray}
			props={{
				name,
				searchProducts,
				cachedProducts,
				setCachedProducts,
				inputId,
			}}
		/>
	);
};

const RenderFieldArray = ({
	fields,
	meta,
	inputId,
	searchProducts,
	cachedProducts,
	setCachedProducts,
	name,
}) => {
	return (
		<FormBlock>
			<Label htmlFor={inputId}>
				<FormattedMessage id="Preferred building product state (product selection)" />
			</Label>
			<ReactSelect
				minWidth="150px"
				id={inputId}
				block
				value={fields.getAll()}
				onChange={v => {
					const ids = v.map(p => p.id);
					const currentFields = fields.getAll() || [];
					const existingIds = currentFields.map(p => p.id);

					const newFields = v.filter(p => !existingIds.includes(p.id));
					const deletableIndexes = currentFields
						.map((p, i) => (!ids.includes(p.id) ? i : null))
						.filter(v => Number.isInteger(v));

					deletableIndexes.forEach(i => fields.remove(i));
					newFields.forEach(p => fields.push({...p, preferredEncounterState: null}));
				}}
				isMulti={true}
				async
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				getOptionValue={option => option.id}
				getOptionLabel={option => option.title}
				defaultOptions={cachedProducts}
				loadOptions={(text, callback) => {
					if (cachedProducts.length > 0) {
						// Only filter when we have cached products
						callback(
							cachedProducts.filter(p =>
								p.title.toLowerCase().includes(text.toLowerCase()),
							),
						);
						return;
					}

					searchProducts({
						text: '',
						callback: products => {
							setCachedProducts(products);
							callback(products);
						},
					});
				}}
				onFocus={() => {
					// Fetch products when input is focused (once)
					if (cachedProducts.length > 0) {
						return;
					}

					// TODO: just load all products beforehadn
					searchProducts({
						text: '',
						callback: products => {
							setCachedProducts(products);
						},
					});
				}}
			/>
			{fields.map((member, index, fields) => (
				<ReduxField
					fieldIndex={index}
					key={index}
					name={`${member}.preferredEncounterState`}
					component={RenderStateSelect}
					props={{
						inputId: `${inputId}-${index}`,
						field: fields.get(index),
					}}
				/>
			))}
			<FieldError {...meta} />
		</FormBlock>
	);
};

const RenderStateSelect = ({input, meta, inputId, field}) => {
	const title = field?.title
		? `${field.title}: ${intl.formatMessage({id: 'Preferred building product state'})}`
		: intl.formatMessage({id: 'Preferred building product state'});

	const stateOptions = [
		{label: intl.formatMessage({id: 'Choose...'}), value: ''},
		...Object.keys(productState).map(key => ({
			value: key,
			label: intl.formatMessage({id: productState[key]}),
		})),
	];

	return (
		<FormBlock>
			<Label htmlFor={inputId}>{title}</Label>
			<Select {...input} block stretch id={inputId}>
				{stateOptions.map(({label, value}) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</Select>
			<FieldError {...meta} />
		</FormBlock>
	);
};

ProductStateFormlet.propTypes = {
	name: PropTypes.string.isRequired,
};

export default ProductStateFormlet;
