import services from 'services';
import {describeThrow} from 'utils/errors';
import * as normalize from 'utils/normalize';
import msgs from 'dicts/messages';
import {getResponseData, mapResponseData} from 'utils/app';

let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const defaultIncludes = 'tags,products.pivot';

export const getReasonMappings = (query = {}) =>
	httpJson('GET', '/reasonMappings', {include: defaultIncludes, ...query}, {})
		.catch(describeThrow(intl.formatMessage({id: msgs.contentFetchFailed})))
		.then(mapResponseData(normalize.reasonMapping));

export const createReasonMapping = input =>
	httpJson('POST', '/reasonMappings', {include: defaultIncludes}, {body: input})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.reasonMapping));

export const updateReasonMapping = (id, input) =>
	httpJson('PUT', `/reasonMappings/${id}`, {include: defaultIncludes}, {body: input})
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})))
		.then(getResponseData(normalize.reasonMapping));

export const deleteReasonMapping = id =>
	httpJson('DELETE', `/reasonMappings/${id}`, {}, {}).catch(
		describeThrow(intl.formatMessage({id: msgs.deleteFailed})),
	);

export const restoreReasonMapping = id =>
	httpJson('POST', `/reasonMappings/${id}/restore`, {}, {})
		.then(getResponseData(normalize.reasonMapping))
		.catch(describeThrow(intl.formatMessage({id: msgs.saveFailed})));
