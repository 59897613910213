import {action} from 'utils/redux';
import namespace from './namespace';
import {P} from 'utils/types';

const creator = action(namespace);

export const initialize = creator('initialize');

export const destroy = creator('destroy');

export const _initialize = creator('_initialize');

export const createLead = creator('createLead');

export const editLead = creator('editLead', P.Object);

export const openDeleteLeadModal = creator('openDeleteLeadModal', P.Object);

export const closeDeleteLeadModal = creator('closeDeleteLeadModal');

export const removeLead = creator('removeLead', P.Object);

export const _removedLead = creator('_removedLead');

export const _setCategories = creator('_setCategories');

export const _setChannels = creator('_setChannels');

export const _setCustomerTypes = creator('_setCustomerTypes');

export const _setSources = creator('_setSources');

export const _opFailed = creator('_opFailed');
