import services from 'services';
import importMaps from 'services/importMaps';
import {map, pipe, prop} from 'ramda';
import {describeThrow} from 'utils/errors';
import {buildingsInclude} from './constants';
import * as normalize from 'utils/normalize';
import {mapResponseData, getResponseData} from 'utils/app';
import {apiUrl} from 'constants/app';
import {
	styles as mapStyles,
	maxZoom,
	localeCenterWeb,
	localeZoomFactor,
	areasMaxDetailZoom,
} from 'constants/maps';
import {deleteChildren} from 'utils/dom';
import {
	safeGetFeaturesAtPixel,
	safeHasFeatureAtPixel,
	addTooltipToLayers,
	renderAreaTooltip,
	_getMapTileSource,
} from 'io/maps';
import {encodeQuery} from 'utils/url';
import {getJsonProperties, getJsonProperty} from 'utils/maps';
import {createFormData} from 'utils/http';

let http = null;
services.waitFor('api').then(x => (http = x.http));
let httpJson = null;
services.waitFor('api').then(x => (httpJson = x.httpJson));
let intl = null;
services.waitFor('intl').then(x => (intl = x));

const decorateMapMethod = method => args =>
	importMaps()
		.then(method(args))
		.catch(describeThrow(intl.formatMessage({id: 'Error loading map'})));

// resources

export const getBuildings = query =>
	httpJson('get', '/buildings', {include: buildingsInclude, ...query})
		.catch(
			describeThrow(
				intl.formatMessage({id: 'Could not load buildings. Try refreshing the page.'}),
			),
		)
		.then(mapResponseData(map(normalize.building)));

export const getBuildingsIds = query =>
	httpJson('get', '/buildings', {...query})
		.catch(
			describeThrow(
				intl.formatMessage({
					id: 'No buildings could be selected. Try refreshing the page.',
				}),
			),
		)
		.then(getResponseData(map(normalize.buildingIds)));

export const postBuildingsUpdates = data =>
	httpJson('post', '/buildings/update', {}, {body: data}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to start mass saving'})),
	);

export const massTagBuildings = data =>
	httpJson('post', '/buildings/massTag', {}, {body: data}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to start mass saving'})),
	);

export const massUpdateProductState = data =>
	httpJson('post', '/buildings/massUpdateProductState', {}, {body: data}).catch(
		describeThrow(intl.formatMessage({id: 'Failed to start mass saving'})),
	);
// maps

const initZoom = 4 * localeZoomFactor;

const _getAreasStyle =
	({selectedAreaIds}) =>
	imports => {
		const {openLayers: ol} = imports;

		const sty = mapStyles(ol);

		return feature =>
			new ol.style.Style({
				fill: selectedAreaIds.has(getJsonProperty(feature, 'id'))
					? sty.areasHlFill
					: sty.areasFill,
				stroke: sty.areasStroke,
				// note: could move labels into a separate json layer at some point
				text: new ol.style.Text({
					text: getJsonProperty(feature, 'title'),
					fill: sty.areasTextFill,
					stroke: sty.areasTextStroke,
					font: sty.areasFont,
				}),
			});
	};
export const getAreasStyle = decorateMapMethod(_getAreasStyle);

const _getAreasSource =
	({apiToken, areasType}) =>
	imports => {
		const {openLayers: ol} = imports;

		return new ol.source.VectorTile({
			format: new ol.format.MVT({dataProjection: 'EPSG:4326'}),
			url: `${apiUrl}/maps/areas/mvt/{z}/{x}/{y}${encodeQuery({
				type: areasType,
				token: apiToken,
			})}`,
			maxZoom: areasMaxDetailZoom,
		});
	};
export const getAreasSource = decorateMapMethod(_getAreasSource);

const createAreasLayer =
	({apiToken, areasType, selectedAreaIds}) =>
	imports => {
		const {openLayers: ol} = imports;

		const layer = new ol.layer.VectorTile({
			source: _getAreasSource({apiToken, areasType})(imports),
			style: _getAreasStyle({selectedAreaIds})(imports),
		});

		return layer;
	};

const _initAreasMap =
	({apiToken, areasType, selectedAreaIds, onAreaClick}) =>
	imports => {
		// keep some local state
		let currHoverAreaId = null;

		const {openLayers: ol} = imports;

		const mapEl = document.querySelector('#buildings-areas-selection-map');
		if (!mapEl) {
			return Promise.reject(new Error('Map element not found'));
		}

		const view = new ol.View({
			projection: 'EPSG:3857',
			center: localeCenterWeb,
			zoom: initZoom,
			maxZoom,
			enableRotation: false,
			constrainResolution: true,
		});

		const areasLayer = createAreasLayer({apiToken, areasType, selectedAreaIds})(imports);

		const map = new ol.Map({
			target: 'buildings-areas-selection-map',
			view,
			layers: [
				new ol.layer.Tile({
					source: _getMapTileSource({sourceId: 'here-light'})(imports),
				}),
				// TODO: generic toggling for google maps - MVT layers don't work with it so need to use geojson sources and strict min zooms
				//new olgm.layer.Google(),
				areasLayer,
			],
			//interactions: olgm.interaction.defaults(),
		});

		const areaTooltipEl = document.getElementById(
			'buildings-areas-selection-map-area-tooltip',
		);

		const areaTooltip = new ol.Overlay({
			element: areaTooltipEl,
			positioning: 'bottom-center',
			offset: [0, -5],
		});

		const updateAreaTooltip = areaFeature => {
			const {id, title, subtitle} = getJsonProperties(areaFeature, [
				'id',
				'title',
				'subtitle',
			]);
			if (id === currHoverAreaId) return;
			currHoverAreaId = id;
			deleteChildren(areaTooltipEl);
			renderAreaTooltip({tooltipEl: areaTooltipEl, title, subtitle});
		};

		addTooltipToLayers({
			mapEl,
			map,
			layers: [areasLayer],
			tooltipEl: areaTooltipEl,
			tooltip: areaTooltip,
			updateTooltip: updateAreaTooltip,
		});

		map.on('click', e => {
			const features = safeGetFeaturesAtPixel(map, e.pixel, {
				layerFilter: l => l === areasLayer,
			});
			if (!features) return;
			onAreaClick(features[0]);
		});

		map.on('pointermove', e => {
			const pixel = map.getEventPixel(e.originalEvent);
			const hit = safeHasFeatureAtPixel(map, pixel, {
				layerFilter: l => l === areasLayer,
			});
			map.getViewport().style.cursor = hit ? 'pointer' : '';
		});

		//const olgmInst = new olgm.GoogleMaps({map});
		//olgmInst.activate();
		// use when removing olgm layer
		//olgmInst.deactivate();

		return Promise.resolve({map, areasLayer});
	};
export const initAreasMap = decorateMapMethod(_initAreasMap);

export const findBuildingsFromCsvFile = data => {
	return http(
		'POST',
		'/buildings/findBuildingsFromFile',
		{},
		{body: createFormData(data)},
	)
		.catch(
			describeThrow(
				intl.formatMessage({
					id: 'Failed to save',
				}),
			),
		)
		.then(res => res.json())
		.then(pipe(prop('data')));
};
