import {
	evolve,
	assoc,
	until,
	repeat,
	pick,
	merge,
	pipe,
	sortBy,
	map,
	prop,
	any,
	contains,
	has,
} from 'ramda';
import {replace} from 'utils/objects';
import initState from './state';
import {
	getWeekBoundaries,
	increaseHour,
	getHourBoundaries,
	addHours,
	setUtcHour,
	toLocaleDateString,
} from 'utils/time';
import {appointmentsHourRange} from 'constants/domain';
import {over} from 'utils/lenses';
import areaTitle from 'utils/areaTitle';
import {isSalesAssignment} from 'utils/calendarResources';
import {transform} from 'ol/proj';
import {formatEncounterFormFillFormOutput} from 'utils/formData';
import {parseNullableNumber} from 'utils/fields';

export const parseUrlQuery = (query, showRequests) => {
	const vals = evolve(
		{
			teamId: str => (str === 'requests' ? str : Number(str)),
			weekSample: weekSample => (weekSample ? new Date(Number(weekSample)) : new Date()),
			buildingId: Number,
			clientId: Number,
			leadId: parseNullableNumber,
			openToDate: str => (str ? new Date(str) : null),
			openItemId: x => (x ? Number(x) : null),
			organizationId: x => (x ? Number(x) : null),
			includeTrashedEvents: includeTrashedEvents => includeTrashedEvents === 'true',
		},
		query,
	);

	// don't allow user to change teamId to requests manually via url query if it's not shown
	if (vals.teamId === 'requests' && !showRequests) {
		vals.teamId = null;
	}

	return {calendarQuery: replace(vals, initState.calendarQuery)};
};

export const formatUrlQuery = ({calendarQuery}) => {
	const {teamId, weekSample, includeTrashedEvents} = calendarQuery;

	return {
		includeTrashedEvents,
		teamId,
		weekSample: weekSample.getTime(),
	};
};

export const formatFetchableCalendarQuery = ({calendarQuery}) => {
	const {teamId, weekSample, includeTrashedEvents} = calendarQuery;
	const [dateFrom, dateTo] = getWeekBoundaries(weekSample).map(d => d.toJSON());

	if (teamId === 'requests') {
		return {
			dateFrom,
			dateTo,
			onlyRequests: true,
		};
	}

	return {
		includeTrashedEvents,
		teamId,
		dateFrom,
		dateTo,
	};
};

export const formatCalendarResourceAddFormOutput = ({form, slotSelection, teamId}) => {
	const {type, date, hour} = slotSelection;
	const areas = form.areas.map(a => ({id: a}));
	const products = form.products ? form.products.map(a => ({id: a})) : [];
	const itemBase = {teamId, areas, products};

	if (type === 'day') {
		const {hourRangeStart, hourRangeEnd, interval, count} = form;
		const startTime = increaseHour(date, hourRangeStart);
		const endTime = increaseHour(date, hourRangeEnd + 1);

		const items = until(
			({startTime}) => startTime >= endTime,
			({startTime, items}) => {
				const [dateFrom, dateTo] = getHourBoundaries(startTime);
				const newItem = {...itemBase, dateFrom, dateTo};
				const newItems = repeat(newItem, count);
				return {startTime: addHours(startTime, interval), items: [...items, ...newItems]};
			},
			{startTime, items: []},
		)['items'];
		return items;
	} else {
		const time = increaseHour(date, hour);
		const [dateFrom, dateTo] = getHourBoundaries(time);
		const newItem = {...itemBase, dateFrom, dateTo};
		const items = repeat(newItem, form.count);
		return items;
	}
};

export const formatCalendarResourceAddFormInput = team => {
	return {
		count: team && team.users ? team.users.length - 1 : 1,
		interval: 1,
		hourRangeStart: appointmentsHourRange[0],
		hourRangeEnd: appointmentsHourRange[1],
		areas: [],
	};
};

export const formatCalendarResourceEditFormInput = resource => {
	if (!resource) return {};

	return {
		areas: resource.areas.map(a => a.id),
		date: resource.dateFrom,
		teamId: resource.teamId,
		salesmanId: resource.salesmanId,
		products: resource.products?.map(p => p.id) || [],
	};
};

export const formatCalendarEventEditFormInput = event => {
	if (!event) return {};
	return {
		startsAt: new Date(event.startsAt),
		endsAt: new Date(event.endsAt),
		teamId: event.teamId,
		title: event.title,
		description: event.description,
		resolved: event.resolved,
		showOnTeamCalendar: event.showOnTeamCalendar,
	};
};

export const formatCalendarResourceEditFormOutput = ({form, selectedItem, teams}) => {
	const areas = form.areas ? form.areas.map(a => ({id: a})) : [];

	const [dateFrom, dateTo] = form.date
		? selectedItem.type === 'bonus'
			? [setUtcHour(form.date, 0, 0, 0, 0), setUtcHour(form.date, 0, 0, 0, 0)]
			: getHourBoundaries(form.date)
		: [null, null];

	const organizationId = teams.filter(t => t.id === form.teamId)[0].organizationId;
	const products = form.products ? form.products.map(a => ({id: a})) : [];

	// only include reserverId if it's present
	const reserver = has('reserverId', form) ? {reserverId: form.reserverId} : {};

	return {
		id: selectedItem.id,
		areas,
		dateFrom,
		dateTo,
		teamId: form.teamId,
		salesmanId: form.salesmanId,
		organizationId,
		products,
		...reserver,
	};
};

export const formatCalendarEncounterAddFormOutput = ({form, slotSelection, teamId}) => {
	const {
		building,
		clientId,
		reserver,
		salesmanId,
		requestSource,
		requestDate,
		isRequest,
		appointmentType,
		ihSource,
		formFill,
		products,
	} = form;
	const {date, hour} = slotSelection;
	const time = increaseHour(date, hour);
	const [dateFrom, dateTo] = getHourBoundaries(time);

	const calendarResource = {
		buildingId: building.id,
		clientId,
		reserverId: reserver.id,
		salesmanId,
		teamId,
		dateFrom,
		dateTo,
		appointmentType,
		ihSource,
		requestSource: isRequest ? requestSource : null,
		requestDate: isRequest ? requestDate : null,
		products: products ? products.map(a => ({id: a})) : [],
	};

	const formFillData =
		formFill && formFill.description
			? formatEncounterFormFillFormOutput(formFill, true)
			: null;

	return {
		calendarResource,
		formFill: formFillData,
	};
};

export const formatItemReservationCancellationOutput = (item, cancelled) => {
	let newItem = null;

	if (item.bonus && cancelled) {
		const date = setUtcHour(item.dateFrom, 0, 0, 0, 0);
		newItem = merge(item, {
			dateFrom: date,
			dateTo: date,
			salesmanId: isSalesAssignment(item) ? item.salesmanId : null,
		});
	} else {
		newItem = assoc('cancelled', cancelled, item);
	}

	return pick(['id', 'cancelled', 'dateFrom', 'dateTo', 'salesmanId'], newItem);
};

// PERM: ignore-visible-for-salesman
export const isSalesmanUser = user => !!user.roles.find(r => r.type === 'salesman');

// PERM: team-calendar-items-manage
export const isReadOnlyUser = user =>
	!!user.roles.find(r => r.slug === 'asennuspaallikko');

// PERM: team-calendar-show-requests
export const showRequestsForUser = user =>
	any(r => contains(r.slug, ['admin', 'telepaeaellikkoe']), user.roles);

export const sortTeams = pipe(
	sortBy(prop('title')),
	map(_team => {
		let team = _team;
		if (team.areas) {
			team = over(['areas'], sortBy(areaTitle), team);
		}
		if (team.users) {
			team = over(
				['users'],
				sortBy(u => (u.lastName || '') + (u.lastName || '')),
				team,
			);
		}
		return team;
	}),
);

export const formatCalendarResourceDateFormInput = resource => {
	if (!resource) return {};

	return {
		date: resource.dateFrom,
	};
};

export const formatCalendarResourceDateFormOutput = ({form, item, userId}) => {
	const [dateFrom, dateTo] = form.date ? getHourBoundaries(form.date) : [null, null];

	return {
		id: item.id,
		salesmanId: userId,
		dateFrom,
		dateTo,
	};
};

export const formatEncounterAddFormInput = ({user, building, clientId, lead}) => {
	if (lead) {
		return {
			reserver: user,
			requestDate: toLocaleDateString(new Date()),
			appointmentType: 'call',
			ihSource: null,
			building,
			clientId,
			formFill: {
				description: lead ? lead.comment : '',
			},
			isRequest: true,
			requestSource: lead.marketingSourceId,
			products: (lead?.products || []).map(p => p.id),
		};
	}
	return {
		reserver: user,
		requestDate: toLocaleDateString(new Date()),
		appointmentType: 'call',
		ihSource: 'call',
		building,
		clientId,
		formFill: {
			description: lead ? lead.comment : '',
		},
	};
};

export const parseAddBuildingFormInitValues = str => {
	if (!str) return {address: '', zip: ''};

	// parse address from left side of comma (if comma exists, otherwise just return the string as address)
	const splitted = str.indexOf(',') > -1 ? str.split(',') : [];
	const address = splitted[0] ? splitted[0].trim() : str.trim();
	// parse zip (number) from right side of comma
	const zip = splitted[1] && splitted[1].match(/\d+/) ? splitted[1].match(/\d+/)[0] : '';

	return {
		address,
		zip,
	};
};

export const formatAddBuildingOutput = building => {
	const transformedCoords = transform(building.coords, 'EPSG:3857', 'EPSG:4326');

	return {
		address: building.address,
		zip: building.zip,
		type: building.type,
		manufacturingYear: building.manufacturingYear,
		location: {lng: transformedCoords[0], lat: transformedCoords[1]},
	};
};

export const formatReminderAddFormInput = slotSelection => {
	const date = new Date(slotSelection.date).setHours(slotSelection.hour);

	return {callAt: new Date(date)};
};

export const checkTeamCalendarReminderVisibility = ({teamId, userTeam = {}}) => {
	return teamId === userTeam.id ? true : false;
};

export const formatClientFormOutput = form => {
	return {...form, emailMarketing: form.emailMarketing ? new Date() : null};
};

export const formatCalendarEventCreateFormOutput = ({form}) => {
	const {
		startsAt,
		endsAt,
		user,
		team,
		title,
		description,
		showOnTeamCalendar = false,
		type,
		building,
		client,
	} = form;

	return {
		startsAt,
		endsAt,
		title,
		description,
		showOnTeamCalendar,
		userId: user.id,
		teamId: team.id,
		calendarEventTypeId: type.id,
		buildingId: building?.id || null,
		clientId: client?.id || null,
		resolved: false,
	};
};

export const formatCalendarEventEditFormOutput = ({form, selectedItem}) => {
	const {startsAt, endsAt, resolved, showOnTeamCalendar, title, description} = form;

	return {
		id: selectedItem.id,
		startsAt,
		endsAt,
		resolved,
		showOnTeamCalendar,
		title,
		description,
	};
};

export const formatCalendarEventAddFormInput = (slotSelection, user, team) => {
	let startsAt = null;
	let endsAt = null;

	if (slotSelection?.date) {
		startsAt = new Date(slotSelection.date);
		startsAt.setHours(slotSelection.hour);
		endsAt = new Date(slotSelection.date);
		endsAt.setHours(slotSelection.hour + 1);
	}

	return {startsAt, endsAt, team, user};
};
